import React, { useState } from "react";
import { COMPETENCES } from "../../utils/competences";
import { EnemCompetences } from "../../utils/enums/enem-competence.enum";
import { classNames } from "../../utils/functions/ClassNames";
import Button from "../Button";
import InputText from "../InputText";
import Select from "../Select";
import TextArea from "../TextArea";

interface Props {
  data?: {
    index: number;
    color?: string;
    competence: string;
    description: string;
    id?: string;
  };
  competences?: any[];
  onlyView?: boolean;
  removeRegion: () => void;
  setCompetence: (data: { competence?: string; description?: string }) => void;
}

const Region: React.FC<Props> = ({
  data,
  competences,
  removeRegion,
  setCompetence,
  onlyView,
}) => {
  const [hover, setHover] = useState(false);
  const [description, setDescription] = useState(data?.description || "");

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        background: data?.color + "40" || "#ff000040",
      }}
      className={classNames(
        onlyView ? "cursor-default" : "",
        "w-full h-full relative"
      )}
    >
      {!onlyView && hover && (
        <div className="absolute top-[-55px] left-0">
          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
            }}
            className="absolute bottom-[-9px] left-[5px] border-t-[10px] border-t-white dark:border-t-gray-700  cursor-pointer"
          ></div>

          {data?.id && (
            <>
              <div className="absolute bottom-[-15px] w-full h-[20px] bg-transparent"></div>
              <Select
                onChange={(e) =>
                  setCompetence({
                    competence: e?.target?.value,
                  })
                }
                defaultValue={data?.competence}
                value={data?.competence}
              >
                <option value="">Selecione uma competência</option>
                {competences?.map((competence) => (
                  <option key={competence?.name} value={competence?.name}>
                    {competence.value}
                  </option>
                ))}
              </Select>
            </>
          )}
        </div>
      )}

      {!onlyView && (
        <p
          className="w-[30px] h-[30px] text-sm bg-red-500/[.80] hover:bg-red-500 cursor-pointer absolute top-[-40px] right-[-15px] rounded-md flex items-center justify-center text-white"
          onClick={() => !onlyView && removeRegion()}
        >
          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
            }}
            className="absolute bottom-[-10px] left-[5px] border-t-[10px] border-t-red-500/[.80] hover:border-t-red-500 cursor-pointer"
          ></div>
          X
        </p>
      )}

      {!onlyView && hover && data?.competence && (
        <div className="absolute bottom-[-50px] left-0">
          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
            }}
            className="absolute top-[-58px] left-[5px] border-b-[10px] border-b-white dark:border-b-gray-700 cursor-pointer"
          ></div>
          {data?.id && (
            <>
              <div className="absolute top-[-15px] w-full h-[20px] bg-transparent"></div>
              <TextArea
                onChange={(e) => setDescription(e?.target?.value)}
                defaultValue={description}
                value={description}
                placeholder="Escreva aqui um resumo do problema encontrado"
                className="absolute bottom-[-87px] w-full min-w-[200px] z-40"
              >
                <Button
                  className="absolute bottom-[10px] right-[10px] z-10 text-xs py-1 shadow-none"
                  color="success"
                  label="Salvar"
                  onClick={() => setCompetence({ description })}
                />
              </TextArea>
            </>
          )}
        </div>
      )}

      {onlyView && hover && data?.competence && (
        <div
          style={{ zIndex: 10 }}
          className="absolute border-[1px] dark:border-gray-700 top-0 right-[-180px] max-w-[200px] bg-white dark:bg-gray-900 p-4 rounded-md shadow-sm shadow-gray-100 dark:shadow-gray-600"
        >
          <div
            style={{
              width: 0,
              height: 0,
              borderTop: "10px solid transparent",
              borderBottom: "10px solid transparent",
            }}
            className="absolute top-[0px] left-[-9px] border-r-[10px] border-r-white dark:border-r-gray-900 cursor-pointer"
          ></div>

          <p className="text-lg strong text-gray-900 dark:text-white">
            {COMPETENCES.find((c) => c.name === data.competence)?.value}
          </p>
          <p className="text-sm text-gray-700 dark:text-gray-300">
            {data.description}
          </p>
        </div>
      )}
    </div>
  );
};

export default Region;
