import React from "react";

interface Props {
  label?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  maxLength?: number;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: React.FC<Props> = ({
  label,
  defaultValue,
  value,
  onChange,
  maxLength,
  placeholder,
  className,
  children,
}) => {
  return (
    <div className={className}>
      <label
        htmlFor="comment"
        className="block text-sm font-medium text-gray-700 dark:text-gray-100"
      >
        {label}
      </label>
      <div className="mt-1">
        <textarea
          className="textarea w-full outline-none"
          rows={4}
          name="comment"
          id="comment"
          placeholder={placeholder}
          maxLength={maxLength}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
        ></textarea>
        {children}
      </div>
    </div>
  );
};

export default TextArea;
