import { API_URL } from "../utils/api";
import { ErrorInterface } from "../utils/interfaces/error.interface";
import { Profile, Role } from "../utils/interfaces/models";

interface CreateInterface {
  email: string;
  name: string;
  password: string;
  role: Role;
  school?: string;
  access_until: Date;
}

interface BodyUpdate {
  name?: string;
  email?: string;
  password?: string;
  access_until?: Date;
}

interface UpdateInterface {
  body: BodyUpdate;
}

interface AdmUpdateProfileInterface {
  id: string;
  body: BodyUpdate & {
    points?: number;
  };
}

interface UpdateImageInterface {
  image: any;
}

interface UpdateResponseInterface extends ErrorInterface {
  profile: Profile;
}

interface CreateResponseInterface extends ErrorInterface {
  profile: Profile;
}

interface UpdateImageResponseInterface extends ErrorInterface {
  profile: Profile;
}

interface FindOneResponseInterface extends ErrorInterface {
  profile: Profile;
}

interface FindAllResponseInterface extends ErrorInterface {
  profile: Profile[];
}

export const profileAPI = {
  create: async (data: CreateInterface): Promise<CreateResponseInterface> => {
    return await fetch(`${API_URL}/profile`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { profile: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findAll: async (): Promise<FindAllResponseInterface> => {
    return await fetch(`${API_URL}/profile/all`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { profile: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findOne: async (): Promise<FindOneResponseInterface> => {
    return await fetch(`${API_URL}/profile`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { profile: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  update: async (data: UpdateInterface): Promise<UpdateResponseInterface> => {
    return await fetch(`${API_URL}/profile`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.body),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { profile: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  admUpdate: async (
    data: AdmUpdateProfileInterface
  ): Promise<UpdateResponseInterface> => {
    return await fetch(`${API_URL}/profile/adm/${data.id}`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.body),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { profile: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  updateImage: async (
    data: UpdateImageInterface
  ): Promise<UpdateImageResponseInterface> => {
    const formData = new FormData();
    formData.append("image", data.image);

    return await fetch(`${API_URL}/profile/image`, {
      method: "PATCH",
      credentials: "include",
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      body: formData,
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { profile: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },
};
