import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { videoAPI } from "../../../../api/video.api";
import VideoCard from "../../../../components/VideoCard";

const DefaultGridCover: React.FC = ({ children }) => {
  return (
    <div className="mt-8">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <ul
          role="list"
          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-4 lg:grid-cols-3 xl:gap-x-4"
        >
          {children}
        </ul>
      </div>
    </div>
  );
};

const VideosPage = () => {
  const navigate = useNavigate();
  const { data: videos, status } = useQuery(
    "videos-student",
    videoAPI.findAll,
    {
      // cacheTime: 1000 * 60,
      // staleTime: 1000 * 60,
      refetchOnWindowFocus: false,
    }
  );

  if (status === "loading") {
    return (
      <DefaultGridCover>
        {[1, 1, 1, 1, 1, 1].map((video, i) => (
          <VideoCard key={i} loading />
        ))}
      </DefaultGridCover>
    );
  }

  if (!videos?.video) {
    return (
      <div className="text-center mt-16">
        <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">
          Nenhum video para mostrar
        </h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
          Finalize o pagamento para ter acesso aos vídeos!
        </p>
      </div>
    );
  }

  return (
    <DefaultGridCover>
      {videos &&
        videos?.video?.map((video) => (
          <VideoCard
            key={video.id}
            onClick={() => navigate(`/app/player/${video.id}`)}
            image={video.image}
            title={video.title}
            subTitle={video.sub_title || ""}
            id={video.id}
            buttonText="Assistir"
          />
        ))}
    </DefaultGridCover>
  );
};

export default VideosPage;
