import React, { useState } from "react";
import { useAuth } from "../../../../utils/context/auth";
import { profileAPI } from "../../../../api/profile.api";
import Button from "../../../../components/Button";
import InputText from "../../../../components/InputText";

const SettingsPage: React.FC = () => {
  const { user, notification } = useAuth();
  const [newPass, setNewPass] = useState("");
  const [newPassConfirm, setNewPassConfirm] = useState("");
  const [updating, setUpdating] = useState(false);

  const updateData = async () => {
    if (newPass !== newPassConfirm) {
      return notification("As senhas inseridas devem ser iguais!", {
        type: "warning",
      });
    }

    if (newPass.trim() === "") {
      return notification("A senha não pode ser vazia!", {
        type: "warning",
      });
    }

    setUpdating(true);

    const { error } = await profileAPI.update({
      body: {
        password: newPass,
      },
    });

    setUpdating(false);

    if (error) {
      return notification("Erro ao atualizar dados!", {
        type: "error",
      });
    }

    setNewPass("");
    setNewPassConfirm("");
    return notification("Dados atualizados com sucesso!", {
      type: "success",
    });
  };

  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8">
          <div className="lg:grid lg:grid-cols-18">
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 p-8 pt-2">
              <section aria-labelledby="payment-details-heading">
                <form action="#" method="POST">
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="py-6 px-4 sm:p-6 bg-base-100">
                      <div>
                        <h2
                          id="payment-details-heading"
                          className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                        >
                          Detalhes da conta
                        </h2>
                      </div>

                      <div className="mt-6 grid grid-cols-4 gap-6">
                        <div className="col-span-4 sm:col-span-2">
                          <InputText
                            type="text"
                            name="name"
                            id="name"
                            label="Nome"
                            disabled
                            defaultValue={user?.profile?.name}
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <InputText
                            type="email"
                            name="email-address"
                            id="email-address"
                            label="Email"
                            disabled
                            defaultValue={user?.email}
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <InputText
                            type="password"
                            name="new-pass"
                            id="new-pass"
                            autoComplete="disabled"
                            label="Senha"
                            value={newPass}
                            onChange={(e) => setNewPass(e.target.value)}
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <InputText
                            type="password"
                            name="new-pass-confirm"
                            id="new-pass-confirm"
                            autoComplete="disabled"
                            label="Confirmar nova senha"
                            value={newPassConfirm}
                            onChange={(e) => setNewPassConfirm(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
                      <Button
                        type="button"
                        onClick={() => updateData()}
                        loading={updating}
                        label={"Atualizar"}
                        color="primary"
                      />
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default SettingsPage;
