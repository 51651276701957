import React, { useState } from "react";
import {} from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { useQuery } from "react-query";
import { videoAPI } from "../../../../api/video.api";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../utils/context/auth";
import Button from "../../../../components/Button";

const PlayerPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { data: video } = useQuery(
    ["video", id],
    () => videoAPI.findOne({ id: id! }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { user, setUser } = useAuth();

  // const setWatched = async () => {
  //   if (user.videos_watched?.find((el: any) => el.id === id)) {
  //     return;
  //   }

  //   setLoading(true);

  //   setLoading(false);

  //   setUser({
  //     ...user,
  //     videos_watched: [...user?.videos_watched, { id: id! }],
  //   });
  // };

  return (
    <div className="p-8 flex items-center w-full h-screen content-center flex-col ">
      <div className="w-full w-[100%] h-[45%] lg:w-[85%] lg:h-[80%] md:w-[90%] md:h-[55%] sm:w-[90%] sm:h-[65%]  bg-base-100 rounded-lg shadow-lg flex flex-col">
        {/* <Vimeo
          video={video?.url!}
          className="w-full h-[100%] lg:w-[100%] lg:h-[100%] rounded-t-lg"
          width={"100%"}
          height={"100%"}
          dnt
          responsive
          onEnd={() => setWatched()}
        /> */}
        <iframe
          title={"videoFrame"}
          src={video?.video?.url}
          // width="640"
          // height="360"
          frameBorder="0"
          // className="max-w-sm sm:max-w-lg md:max-w-lg lg:max-w-lg xl:max-w-xl"
          className="w-full h-[100%] lg:w-[100%] lg:h-[100%] rounded-t-lg"
          allow="fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>

        <div className="w-full flex flex-row justify-between items-center p-4">
          <h3 className="text-xl mt-4 p-5 text-gray-800 dark:text-gray-100 md:text-2xl lg:text-3xl">
            {video?.video?.title}
          </h3>
          {/* {!user?.videos_watched?.find((e: { id: string }) => e.id === id) && (
            <Button
              onClick={() => setWatched()}
              color="success"
              loading={loading}
            >
              <CheckIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              Concluir
            </Button>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default PlayerPage;
