import React, { useState } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";
import { useAuth } from "../../../../../utils/context/auth";
import Button from "../../../../../components/Button";
import InputText from "../../../../../components/InputText";
import Select from "../../../../../components/Select";
import DatePicker from "../../../../../components/DatePicker";
import Table from "../../../../../components/Table";
import { teacherAPI } from "../../../../../api/teacher.api";

const TeachersCoordinatorPage: React.FC = () => {
  const { data: students, refetch } = useQuery(
    "teachers",
    () => teacherAPI.findAll(),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 0,
      staleTime: 1000 * 0,
    }
  );
  const { notification } = useAuth();
  const [access_until, setAccess_until] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);

  const createSchool = async () => {
    if (password !== passwordConfirm) {
      return notification("As senhas inseridas devem ser iguais!", {
        type: "warning",
      });
    }

    if (password.trim() === "") {
      return notification("A senha não pode ser vazia!", {
        type: "warning",
      });
    }

    if (password.length < 8) {
      return notification("A deve ter no minimo 8 caracteres!", {
        type: "warning",
      });
    }

    setLoading(true);
    const { error, user, message } = await teacherAPI.create({
      name,
      access_until: new Date(access_until),
      email,
      password,
      force_new_password: true,
      verified: true,
    });

    setLoading(false);
    if (error) {
      return notification(error ?? "Erro ao criar usuário!", {
        type: "error",
      });
    }

    refetch();

    setAccess_until("");
    setEmail("");
    setName("");
    setPassword("");
    setPasswordConfirm("");
    setName("");

    return notification("Usuário criado com sucesso", {
      type: "success",
    });
  };

  const update = async (data: any) => {
    const { id, ...body } = data;

    const { error, message } = await teacherAPI.update({ id, body });

    if (error && message) {
      return notification(message, {
        type: "error",
      });
    }

    refetch();
    return notification("Usuário atualizado com sucesso", {
      type: "success",
    });
  };

  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 px-4 space-y-8 lg:px-8">
          <section aria-labelledby="payment-details-heading">
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-base-100 py-6 px-4 sm:p-6 ">
                  <div>
                    <h2
                      id="payment-details-heading"
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                    >
                      Cadastrar um novo professor
                    </h2>
                  </div>

                  <div className="mt-6 grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="text"
                        label="Nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="email"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <DatePicker
                        label="Acesso até"
                        onChange={(e) =>
                          setAccess_until(
                            moment(e.target.value).toLocaleString()
                          )
                        }
                        value={moment(access_until).format("YYYY-MM-DD")}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="password"
                        autoComplete="disabled"
                        label="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="password"
                        autoComplete="disabled"
                        label="Confirmar senha"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
                  <Button
                    type="button"
                    onClick={() => createSchool()}
                    loading={loading}
                    label={"Criar"}
                    color="primary"
                  />
                </div>
              </div>
            </form>
          </section>

          <section aria-labelledby="billing-history-heading">
            <Table
              title="Professores"
              data={
                students
                  ? students?.user?.map((s) => ({
                      id: s.id,
                      name: s.profile?.name,
                      email: s.profile?.user?.email,
                      access_until: s.profile?.user?.access_until,
                    }))
                  : []
              }
              fields={[
                {
                  editable: true,
                  field: "name",
                  name: "Nome",
                  type: "text",
                },
                {
                  editable: true,
                  field: "email",
                  name: "Email",
                  type: "text",
                },
                {
                  editable: true,
                  field: "access_until",
                  name: "Acesso até",
                  type: "date",
                },
              ]}
              onSave={(d) => update(d)}
            />
          </section>
        </main>
      </div>
    </>
  );
};

export default TeachersCoordinatorPage;
