import React from "react";
import { classNames } from "../../utils/functions/ClassNames";

interface Props {
  label?: string;
  value?: string;
  min?: string;
  max?: string;
  placeholder?: string;
  defaultValue?: string;
  className?: string;
  name?: string;
  disabled?: boolean;
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputNumber: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames(
        props.disabled ? "cursor-no-drop" : "",
        "form-control w-full"
      )}
    >
      <label className="label">
        <span className="label-text">{props.label}</span>
      </label>
      <input
        placeholder={props.placeholder}
        disabled={props.disabled}
        type={"number"}
        max={props.max}
        min={props.min}
        name={props.name}
        id={props.id || props.name}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        className={classNames(
          props.className ?? "",
          "input input-bordered w-full"
        )}
      />
    </div>
  );
};

export default InputNumber;
