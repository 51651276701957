import React, { useEffect } from "react";
import { classNames } from "../../utils/functions/ClassNames";

interface Props {
  name?: string;
  icon?: any;
  value?: string;
  subtitle?: string;
  href?: string;
  color?: string;
  onClick?: () => void;
}

const CardInfo: React.FC<Props> = (props) => {
  return (
    <div className="stats shadow mb-4 w-full md:w-[47%]">
      <div className="stat">
        <div
          className={classNames("stat-figure ", props.color ?? "text-primary")}
        >
          <props.icon
            className={classNames("h-6 w-6 ", props.color ?? "text-primary")}
            aria-hidden="true"
          />
        </div>
        <div className="stat-title">{props.name}</div>
        <div
          className={classNames("stat-value ", props.color ?? "text-primary")}
        >
          {props.value}
        </div>
        <div className="stat-desc">{props.subtitle}</div>
      </div>
    </div>
  );
};

export default CardInfo;
