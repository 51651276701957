import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { API_URL } from "../../../../../utils/api";
import { useAuth } from "../../../../../utils/context/auth";
import Select from "../../../../../components/Select";
import moment from "moment";
import InputNumber from "../../../../../components/InputNumber";
import { feeAPI } from "../../../../../api/fee.api";
import { textAPI } from "../../../../../api/text.api";
import Button from "../../../../../components/Button";

const FinancialAdminPage = () => {
  const { notification } = useAuth();
  const [corrector, setCorrector] = useState<string | null>(null);
  const [totalToPay, setTotalToPay] = useState(0);
  const [selectedTexts, setSelectedTexts] = useState([]);
  const [photoSrc, setPhotoSrc] = useState<any>(null);
  const { data: texts, refetch } = useQuery(
    ["texts-correcteds-by-corrector", corrector],
    () => textAPI.getTextsFromCorrector(corrector!),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: correctors } = useQuery("correctors", feeAPI.findAll, {
    refetchOnWindowFocus: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (corrector) {
      refetch();
    }
  }, [corrector]);

  const createFee = async () => {
    setLoading(true);

    if (!corrector) {
      notification("Selecione um corretor", {
        type: "error",
      });
      setLoading(false);
      return;
    }

    const dataToSend = {
      corrector_id: corrector,
      texts: selectedTexts,
    };

    const { data, error, message } = await textAPI.payTexts(dataToSend);

    setLoading(false);

    if (error) {
      notification(message || "Ocorreu um erro ao gerar o pagamento", {
        type: "error",
      });
      return;
    }

    notification("Pagamento gerado com sucesso", {
      type: "success",
    });

    setSelectedTexts([]);

    refetch();
  };

  const setNewSelectedTexts = (target: any) => {
    const { checked, value } = target;

    if (checked) {
      setSelectedTexts(Object.assign([], [...selectedTexts, value]));
    } else {
      setSelectedTexts(
        Object.assign(
          [],
          selectedTexts.filter((text) => text !== value)
        )
      );
    }
  };

  useEffect(() => {
    if (selectedTexts.length > 0) {
      const total = selectedTexts.reduce((acc, text) => {
        const textSelected = texts?.data?.find((t) => t.id === text);

        if (textSelected) {
          return acc + (textSelected?.corrector?.value_per_text || 0);
        }

        return acc;
      }, 0);

      setTotalToPay(total);
    } else {
      setTotalToPay(0);
    }
  }, [selectedTexts]);

  return (
    <>
      <main className="max-w-7xl mx-auto pb-10 lg:py-12 px-4 space-y-8 lg:px-8">
        <section aria-labelledby="payment-details-heading">
          <form action="#" method="POST">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-base-100 py-6 px-4 sm:p-6 ">
                <div>
                  <h2
                    id="payment-details-heading"
                    className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                  >
                    Gerar Pagamento
                  </h2>
                </div>

                <div className="mt-6 grid grid-cols-4 gap-6">
                  <div className="col-span-4 sm:col-span-2">
                    <Select
                      label="Corretores"
                      value={corrector ?? ""}
                      onChange={(e) => setCorrector(e.target.value)}
                    >
                      <option>Selecione um corretor</option>
                      {correctors &&
                        correctors?.data?.map((e) => (
                          <option key={e.id} value={e.id}>
                            {e.profile.name}
                          </option>
                        ))}
                    </Select>
                  </div>
                </div>

                <div className="mt-6 w-full">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead className="bg-gray-50 dark:bg-gray-800">
                      <tr>
                        {[
                          "Selecionar",
                          "Status",
                          "Pago",
                          "Data de correção",
                        ].map((e, i) => (
                          <th
                            key={i}
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                          >
                            {e}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-base-100 dark:divide-gray-700">
                      {texts &&
                        texts?.data?.map((text) => (
                          <tr key={text?.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                              <input
                                disabled={text?.paid ? true : false}
                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                value={text?.id}
                                defaultChecked={text.paid ? true : false}
                                onChange={(e) => setNewSelectedTexts(e.target)}
                                id="flexCheckDefault"
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                              {text?.status}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                              {text?.paid ? "Sim" : "Não"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                              <time
                                dateTime={new Date(
                                  text?.corrected_at || text?.updated_at
                                ).toISOString()}
                              >
                                {moment(
                                  text?.corrected_at || text?.updated_at
                                ).format("DD/MM/YYYY HH:mm")}
                              </time>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                {corrector && (
                  <>
                    <div className="mt-6 grid grid-cols-4 gap-6">
                      <div className="col-span-4 sm:col-span-2">
                        <p>
                          <strong>Valor por texto: R$</strong>
                          {
                            correctors?.data?.find((e) => e.id === corrector)
                              ?.value_per_text
                          }
                        </p>
                      </div>
                    </div>

                    <div className="mt-6 grid grid-cols-4 gap-6">
                      <div className="col-span-4 sm:col-span-2">
                        <p>
                          <strong>Total a pagar: R$</strong>
                          {totalToPay}
                        </p>
                      </div>
                    </div>

                    <div className="mt-6 grid grid-cols-4 gap-6">
                      <div className="col-span-4 sm:col-span-2">
                        <Button
                          onClick={() => createFee()}
                          disabled={totalToPay <= 0}
                          color="primary"
                        >
                          Pagar
                        </Button>
                      </div>
                    </div>
                  </>
                )}

                {/* <div className="mt-6 grid grid-cols-4 gap-6">
                  <div className="col-span-4 sm:col-span-2">
                    <UploadPhoto onChange={(e) => createFee(e)} />
                  </div>
                </div> */}
              </div>
            </div>
          </form>
        </section>
      </main>
    </>
  );
};

export default FinancialAdminPage;
