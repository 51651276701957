import React from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../../../../utils/context/auth";
import Table from "../../../../../components/Table";
import { profileAPI } from "../../../../../api/profile.api";
import { correctorAPI } from "../../../../../api/corrector.api";

const CorrectorsAdminPage: React.FC = () => {
  const { data: correctors, refetch } = useQuery(
    "correctors-list-all",
    correctorAPI.findAll,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 0,
      staleTime: 1000 * 0,
    }
  );
  const { notification } = useAuth();

  const edit = async (obj: any) => {
    const { id, ...body } = obj;
    const { message, error } = await correctorAPI.updateValuePerText(
      {
        id,
      },
      {
        ...(body.value_per_text && {
          value_per_text: Number(body.value_per_text),
        }),
      }
    );

    if (error && message) {
      return notification(message, {
        type: "error",
      });
    }

    refetch();
    return notification("Usuário atualizado com sucesso!", {
      type: "success",
    });
  };

  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 px-4 space-y-8 lg:px-8">
          <section aria-labelledby="billing-history-heading">
            <Table
              title="Corretores"
              data={
                correctors
                  ? correctors?.users?.map((s) => ({
                      id: s.id,
                      name: s.profile?.name,
                      email: s.profile?.user?.email,
                      value_per_text: s.value_per_text,
                    }))
                  : []
              }
              fields={[
                {
                  editable: false,
                  field: "name",
                  name: "Nome",
                  type: "text",
                },
                {
                  editable: false,
                  field: "email",
                  name: "Email",
                  type: "text",
                },
                {
                  editable: true,
                  field: "value_per_text",
                  name: "Valor por texto",
                  type: "number",
                },
              ]}
              onSave={(d) => edit(d)}
            />
          </section>
        </main>
      </div>
    </>
  );
};

export default CorrectorsAdminPage;
