/**
 * Model Gift
 *
 */
export type Gift = {
  id: string;
  title: string;
  description: string;
  points: number | null;
  days: number | null;
  email: boolean;
  viewed: boolean;
  profile_id: string;
  createdAt: Date;
  updatedAt: Date;
  profile: Profile;
};

/**
 * Model Video
 *
 */
export type Video = {
  id: string;
  title: string;
  sub_title: string;
  order: number;
  url: string;
  public: boolean;
  image_id: string;
  created_at: Date;
  updated_at: Date;
  deleted: boolean;
  image: Image;
};

/**
 * Model EnemAreaError
 *
 */
export type EnemAreaError = {
  id: string;
  competence: Enem_Competences;
  description: string;
  color: string;
  area_id: string;
  created_at: Date;
  updated_at: Date;
  deleted: boolean;
  area: Area;
};

/**
 * Model Area
 *
 */
export type Area = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  text_id: string;
  created_at: Date;
  updated_at: Date;
  deleted: boolean;
  data: EnemAreaError;
};

/**
 * Model EnemStyle
 *
 */
export type EnemStyle = {
  id: string;
  comp1: number;
  comp2: number;
  comp3: number;
  comp4: number;
  comp5: number;
  text_id: string;
  created_at: Date;
  updated_at: Date;
  deleted: boolean;
};

/**
 * Model Text
 *
 */
export type Text = {
  id: string;
  title: string | null;
  status: Text_Status;
  correction_style: Correction_Style;
  corrector_observation: string | null;
  student_id: string;
  corrector_id: string | null;
  image_id: string | null;
  theme_id: string | null;
  created_at: Date;
  updated_at: Date;
  deleted: boolean;
  student: Student;
  corrector: Corrector | null;
  theme: Theme | null;
  image: Image | null;
  enem_style: EnemStyle | null;
  enem_area_errors: EnemAreaError[];
  areas: Area[] | null;
  paid?: Boolean;
  paid_at?: Date;
  value_paid?: number;
  corrected_at?: Date;
};

/**
 * Model Theme
 *
 */
export type Theme = {
  id: string;
  title: string;
  correction_style: Correction_Style;
  image_id: string;
  school_id: string | null;
  created_at: Date;
  updated_at: Date;
  deleted: boolean;
  motivational: Motivational;
  image: Image;
};

/**
 * Model Motivational
 *
 */
export type Motivational = {
  id: string;
  file_name: string;
  url: string;
  theme_id: string;
  created_at: Date;
  updated_at: Date;
  deleted: boolean;
  theme: Theme;
};

/**
 * Model Image
 *
 */
export type Image = {
  id: string;
  file_name: string;
  url: string;
  created_at: Date;
  updated_at: Date;
  deleted: boolean;
};

/**
 * Model Team
 *
 */
export type Team = {
  id: string;
  name: string;
  school_id: string;
  teacher_id: string | null;
  created_at: Date;
  updated_at: Date;
  deleted: boolean;
  teacher: Teacher | null;
  school: School;
};

/**
 * Model School
 *
 */
export type School = {
  id: string;
  name: string;
  subdomain: string;
  access_until: Date;
  limit_users: number;
  points_per_month: number;
  logo_id: string | null;
  created_at: Date;
  updated_at: Date;
  deleted: boolean;
  logo: Image | null;
};

/**
 * Model Coordinator
 *
 */
export type Coordinator = {
  id: string;
  profile_id: string;
  created_at: Date;
  updatedAt: Date;
  deleted: boolean;
  profile: Profile;
};

/**
 * Model Corrector
 *
 */
export type Corrector = {
  id: string;
  profile_id: string;
  created_at: Date;
  updatedAt: Date;
  deleted: boolean;
  profile: Profile;
  value_per_text?: number;
};

/**
 * Model Teacher
 *
 */
export type Teacher = {
  id: string;
  profile_id: string;
  created_at: Date;
  updatedAt: Date;
  deleted: boolean;
  profile: Profile;
};

/**
 * Model Student
 *
 */
export type Student = {
  id: string;
  team_id: string | null;
  profile_id: string;
  created_at: Date;
  updatedAt: Date;
  deleted: boolean;
  profile: Profile;
  team: Team | null;
};

/**
 * Model Profile
 *
 */
export type Profile = {
  id: string;
  name: string;
  points: number;
  school_id: string | null;
  image_id: string | null;
  user_id: string | null;
  created_at: Date;
  updatedAt: Date;
  deleted: boolean;
  student: Student;
  teacher: Teacher;
  coordinator: Coordinator;
  corrector: Corrector;
  user: User;
  school: School;
  image: Image;
  gifts: Gift[];
};

/**
 * Model User
 *
 */
export type User = {
  id: string;
  email: string;
  password: string;
  access_until: Date;
  force_new_password: boolean;
  verified: boolean;
  roles: Role[];
  created_at: Date;
  updated_at: Date;
  deleted: boolean;
  profile: Profile;
};

export enum Enem_Competences {
  Competencia_1 = "Competencia_1",
  Competencia_2 = "Competencia_2",
  Competencia_3 = "Competencia_3",
  Competencia_4 = "Competencia_4",
  Competencia_5 = "Competencia_5",
}

export enum Text_Status {
  Criado = "Criado",
  Enviado = "Enviado",
  Corrigindo = "Corrigindo",
  Corrigido = "Corrigido",
  Anulado = "Anulado",
  Cancelado = "Cancelado",
  Devolvido = "Devolvido",
}

export enum Correction_Style {
  enem_style = "enem_style",
  fuvest_style = "fuvest_style",
}

export enum Role {
  Estudante = "Estudante",
  Administrador = "Administrador",
  Professor = "Professor",
  Corretor = "Corretor",
  Coordenador = "Coordenador",
}
