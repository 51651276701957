import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "./utils/context/auth";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import LoginPage from "./app/pages/Auth/Login";
import LayoutPrivate from "./components/Layouts/PrivateLayout";
import DashboardPage from "./app/pages/Private/Dashboard/Dashboard";
import VideosPage from "./app/pages/Private/Videos/Videos";
import PlayerPage from "./app/pages/Private/Player/Player";
import SettingsPage from "./app/pages/Private/Settings/Settings";
import NotFoundPage from "./app/pages/NotFound/NotFound";
import PreLoad from "./components/PreLoad";
import ThemesPage from "./app/pages/Private/Themes/Themes";
import WritePage from "./app/pages/Private/Write/Write";
import TextsPage from "./app/pages/Private/Texts/Texts";
import CorrectPage from "./app/pages/Private/Corrector/Correct/Correct";
import SchoolsAdminPage from "./app/pages/Private/Admin/Schools/Schools";
import ClassesCoordinatorPage from "./app/pages/Private/Coordinator/Classes/Classes";
import StudentsCoordinatorPage from "./app/pages/Private/Coordinator/Students/Students";
import TeachersCoordinatorPage from "./app/pages/Private/Coordinator/Teachers/Teachers";
import ClassesTeacherPage from "./app/pages/Private/Teacher/Classes/Classes";
import StudentsTeacherPage from "./app/pages/Private/Teacher/Students/Students";
// import ReportUserPage from "./app/pages/Private/Report/ReportUser";
// import ReportClassPage from "./app/pages/Private/Report/ReportClass";
// import ReportTextsPage from "./app/pages/Private/Report/ReportTexts";
import ThemesAdminPage from "./app/pages/Private/Admin/Themes/Themes";
import DashboardAdminPage from "./app/pages/Private/Admin/Dashboard/Dashboard";
import PaymentsCorrectorPage from "./app/pages/Private/Corrector/Payments/Payments";
import FinancialAdminPage from "./app/pages/Private/Admin/Financial/Financial";
import VideosAdminPage from "./app/pages/Private/Admin/Videos/Videos";
import { Role } from "./utils/interfaces/models";
import TextsUserPage from "./app/pages/Private/Reports/TextsUser";
import ReportTeamPage from "./app/pages/Private/Reports/ReportTeam";
import ReportUserPage from "./app/pages/Private/Reports/ReportUser";
import GiftsAdminPage from "./app/pages/Private/Admin/Gifts/Gifts";
import UsersAdminPage from "./app/pages/Private/Admin/Users/Users";
import CorrectorsAdminPage from "./app/pages/Private/Admin/Correctors/Correctors";

const App = () => {
  const { checkingSession, isAuthenticated, user } = useAuth();

  if (checkingSession) {
    return <PreLoad />;
  }

  return (
    <Routes>
      {checkingSession || !isAuthenticated ? (
        <Route path={"/"} element={<LoginPage />} />
      ) : (
        <Route path="/app" element={<LayoutPrivate />}>
          <Route
            path={"/app"}
            element={
              user.roles.includes(Role.Administrador) ? (
                <DashboardAdminPage />
              ) : (
                <DashboardPage />
              )
            }
          />
          <Route path={"/app/videos"} element={<VideosPage />} />
          <Route path={"/app/player/:id"} element={<PlayerPage />} />

          <Route path={"/app/configuracoes"} element={<SettingsPage />} />

          <Route path={"/app/temas"} element={<ThemesPage />} />
          <Route path={"/app/escrever/:id"} element={<WritePage />} />
          <Route path={"/app/redacao/:id"} element={<WritePage />} />
          <Route path={"/app/minhas-redacoes"} element={<TextsPage />} />

          <Route
            path={"/app/relatorio/usuario/:id"}
            element={<ReportUserPage />}
            User
          />
          <Route
            path={"/app/relatorio/turma/:id"}
            element={<ReportTeamPage />}
          />
          <Route
            path={"/app/redacoes/usuario/:id"}
            element={<TextsUserPage />}
          />

          <Route path={"/app/corrigir"} element={<CorrectPage />} />
          <Route path={"/app/pagamentos"} element={<PaymentsCorrectorPage />} />

          <Route path={"/app/admin/escolas"} element={<SchoolsAdminPage />} />
          <Route path={"/app/admin/usuarios"} element={<UsersAdminPage />} />
          <Route
            path={"/app/admin/corretores"}
            element={<CorrectorsAdminPage />}
          />

          <Route path={"/app/admin/presentes"} element={<GiftsAdminPage />} />

          <Route path={"/app/admin/temas"} element={<ThemesAdminPage />} />
          <Route path={"/app/professor/temas"} element={<ThemesAdminPage />} />

          <Route
            path={"/app/admin/financeiro"}
            element={<FinancialAdminPage />}
          />
          <Route path={"/app/admin/videos"} element={<VideosAdminPage />} />

          <Route
            path={"/app/coordenador/turmas"}
            element={<ClassesCoordinatorPage />}
          />
          <Route
            path={"/app/coordenador/alunos"}
            element={<StudentsCoordinatorPage />}
          />
          <Route
            path={"/app/coordenador/professores"}
            element={<TeachersCoordinatorPage />}
          />

          <Route
            path={"/app/professor/turmas"}
            element={<ClassesTeacherPage />}
          />
          <Route
            path={"/app/professor/alunos"}
            element={<StudentsTeacherPage />}
          />

          <Route path={"/app/*"} element={<NotFoundPage />} />
        </Route>
      )}
      <Route path={"/*"} element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
