import { API_URL } from "../utils/api";
import { ErrorInterface } from "../utils/interfaces/error.interface";
import { Text, Text_Status } from "../utils/interfaces/models";
import { OnlyIDInterface } from "../utils/interfaces/only-id.interface";

interface CreateInterface {
  theme_id: string;
}

interface CreateResponseInterface extends ErrorInterface {
  text: Text;
}

interface FindAllResponseInterface extends ErrorInterface {
  text: Text[];
}

interface FindOneResponseInterface extends ErrorInterface {
  text: Text;
}

interface AddImageInterface {
  id: string;
  image: any;
}

interface AddImageInterfaceResponseInterface extends ErrorInterface {
  text: Text;
}

interface SentInterface {
  id: string;
}

interface FinishInterface {
  id: string;
  body: BodyFinishText;
}

interface DeleteImageInterface {
  id: string;
}

interface DeleteImageResponseInterface extends ErrorInterface {
  text: Text;
}

export interface BodyFinishText {
  corrector_observation: string;
  status: Text_Status;
  comp1?: Number;
  comp2?: Number;
  comp3?: Number;
  comp4?: Number;
  comp5?: Number;
}

export interface CancelTextInterface {
  id: string;
}

interface FindAllCorrectorTextsResponseInterface extends ErrorInterface {
  data: Text[];
}

interface PayInterface {
  corrector_id: string;
  texts: string[];
}

interface PayResponseInterface extends ErrorInterface {
  data: Text[];
}

export const textAPI = {
  create: async (data: CreateInterface): Promise<CreateResponseInterface> => {
    return await fetch(`${API_URL}/text`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { text: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findAll: async (): Promise<FindAllResponseInterface> => {
    return await fetch(`${API_URL}/text`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { text: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findOne: async (data: OnlyIDInterface): Promise<FindOneResponseInterface> => {
    return await fetch(`${API_URL}/text/${data.id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { text: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  addImage: async (
    data: AddImageInterface
  ): Promise<AddImageInterfaceResponseInterface> => {
    const formData = new FormData();
    formData.append("image", data.image);
    // console.log("size", data.image?.size, data.image);

    return await fetch(`${API_URL}/text/${data.id}/image`, {
      method: "PATCH",
      credentials: "include",
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      body: formData,
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { text: data };
      })
      .catch((e) => {
        console.log(e);
        return {
          error: "Error",
          message: "Ocorreu um erro, por favor reinicie a página.",
        };
      });
  },

  deleteImage: async (
    data: DeleteImageInterface
  ): Promise<DeleteImageResponseInterface> => {
    return await fetch(`${API_URL}/text/image/${data.id}/delete`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { text: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  startCorrect: async (): Promise<FindOneResponseInterface> => {
    return await fetch(`${API_URL}/text/correct/start`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { text: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  updateToSent: async (
    data: SentInterface
  ): Promise<FindOneResponseInterface> => {
    return await fetch(`${API_URL}/text/${data.id}/sent`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { text: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  finishCorrection: async (
    data: FinishInterface
  ): Promise<FindOneResponseInterface> => {
    return await fetch(`${API_URL}/text/${data.id}/finish`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.body),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { text: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  cancelText: async (data: CancelTextInterface): Promise<any> => {
    return await fetch(`${API_URL}/text/${data.id}/cancel`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { text: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  unsendText: async (data: CancelTextInterface): Promise<any> => {
    return await fetch(`${API_URL}/text/${data.id}/unsend`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { text: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  getTextsFromCorrector: async (
    id: string
  ): Promise<FindAllCorrectorTextsResponseInterface | null> => {
    if (!id) return null;
    return await fetch(`${API_URL}/text/corrector/texts/${id}`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  payTexts: async (data: PayInterface): Promise<PayResponseInterface> => {
    return await fetch(`${API_URL}/text/corrector/${data.corrector_id}/pay`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        texts: data.texts,
      }),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },
};
