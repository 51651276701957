import { API_URL } from "../utils/api";
import { ErrorInterface } from "../utils/interfaces/error.interface";
import { Gift } from "../utils/interfaces/models";
import { OnlyIDInterface } from "../utils/interfaces/only-id.interface";

interface CreateInterface {
  profile_id: string;
  title: string;
  description: string;
  points?: number;
  days?: number;
  email: boolean;
}

interface CreateResponseInterface extends ErrorInterface {
  gift: Gift;
}

interface FindAllResponseInterface extends ErrorInterface {
  gift: Gift[];
}

interface ViewResponseInterface extends ErrorInterface {
  gift: Gift;
}

export const giftAPI = {
  create: async (data: CreateInterface): Promise<CreateResponseInterface> => {
    return await fetch(`${API_URL}/gift`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { gift: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findAll: async (): Promise<FindAllResponseInterface> => {
    return await fetch(`${API_URL}/gift`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { gift: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  view: async (id: string): Promise<ViewResponseInterface> => {
    return await fetch(`${API_URL}/gift/${id}`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { gift: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },
};
