import { API_URL } from "../utils/api";
import { ErrorInterface } from "../utils/interfaces/error.interface";
import { Theme } from "../utils/interfaces/models";
import { OnlyIDInterface } from "../utils/interfaces/only-id.interface";

interface CreateInterface {
  title: string;
  pdf: any;
  image: any;
}

interface CreateResponseInterface extends ErrorInterface {
  theme: Theme;
}

interface FindAllResponseInterface extends ErrorInterface {
  theme: Theme[];
}

interface FindOneResponseInterface extends ErrorInterface {
  theme: Theme;
}

export const themeAPI = {
  create: async (data: CreateInterface): Promise<CreateResponseInterface> => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("pdf", data.pdf);
    formData.append("image", data.image);

    return await fetch(`${API_URL}/theme`, {
      method: "POST",
      credentials: "include",
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      body: formData,
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { theme: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findAll: async (): Promise<FindAllResponseInterface> => {
    return await fetch(`${API_URL}/theme`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { theme: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findOne: async (data: OnlyIDInterface): Promise<FindOneResponseInterface> => {
    return await fetch(`${API_URL}/theme/${data.id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { theme: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },
};
