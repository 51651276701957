import React from "react";
import { classNames } from "../../utils/functions/ClassNames";

const ModalHeader: React.FC<{
  default?: boolean;
  title?: string;
  onClose?: Function;
}> = ({ children, onClose, default: defaultComponent, title }) => {
  if (defaultComponent) {
    return (
      <div className="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
        <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">
          {title}
        </h3>
        <button
          type="button"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-toggle="defaultModal"
          onClick={() => onClose!()}
        >
          <svg
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    );
  }

  return (
    <div className="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
      {children}
    </div>
  );
};

const ModalBody: React.FC = ({ children }) => {
  return <div className="p-6 space-y-6">{children}</div>;
};

const ModalFooter: React.FC = ({ children }) => {
  return (
    <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
      {children}
    </div>
  );
};

const Modal: React.FC<{ isOpen: boolean }> = ({ children, isOpen }) => {
  return (
    <div
      id="defaultModal"
      aria-hidden="true"
      style={{ zIndex: 1000 }}
      className={classNames(
        isOpen ? "" : "hidden",
        "bg-neutral-500 bg-opacity-30 overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4  flex justify-center items-center md:h-full md:inset-0"
      )}
    >
      <div className="relative px-4 w-full max-w-2xl h-full md:h-auto z-50">
        {/* Modal content  */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {children}
        </div>
      </div>
    </div>
  );
};

export { Modal, ModalHeader, ModalBody, ModalFooter };
