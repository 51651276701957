import React, { useEffect, useState } from "react";

import {
  ClockIcon,
  CreditCardIcon,
  CursorClickIcon,
  ArchiveIcon,
  InformationCircleIcon,
  CalendarIcon,
} from "@heroicons/react/outline";
import { useAuth } from "../../../../utils/context/auth";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import CardInfo from "../../../../components/CardInfo";
// import { reportsAPI } from "../../../../api/reports.api";
import HeaderDashboard from "../../../../components/HeaderDashboard";
import AreaGraph from "../../../../components/AreaGraph";
import BarGraph from "../../../../components/BarGraph";
import { Role } from "../../../../utils/interfaces/models";
import InputText from "../../../../components/InputText";
import { profileAPI } from "../../../../api/profile.api";
import { reportAPI } from "../../../../api/report.api";
import { giftAPI } from "../../../../api/gift.api";

const DashboardPage = () => {
  const { user, notification, setUser } = useAuth();
  const [newPass, setNewPass] = useState("");
  const [newPassConfirm, setNewPassConfirm] = useState("");

  const { data: anualPerformance } = useQuery(
    "year-user-anualPerformance",
    () => reportAPI.anualPerformance(),
    {
      cacheTime: 1000 * 15,
      staleTime: 1000 * 15,
      refetchOnWindowFocus: false,
    }
  );
  const { data: competencesAvg } = useQuery(
    "year-user-competencesAvg",
    () => reportAPI.competencesAvg(),
    {
      cacheTime: 1000 * 15,
      staleTime: 1000 * 15,
      refetchOnWindowFocus: false,
    }
  );
  const { data: textsMonthly } = useQuery(
    "text-sent-monthly",
    () => reportAPI.textsSentMonthly(),
    {
      cacheTime: 1000 * 15,
      staleTime: 1000 * 15,
      refetchOnWindowFocus: false,
    }
  );
  const { data: textsWeekly } = useQuery(
    "text-sent-weekly",
    () => reportAPI.textsSentWeekly(),
    {
      cacheTime: 1000 * 15,
      staleTime: 1000 * 15,
      refetchOnWindowFocus: false,
    }
  );
  const { data: correctorReport } = useQuery(
    "corrector-report",
    () => reportAPI.correctorReport(),
    {
      cacheTime: 1000 * 15,
      staleTime: 1000 * 15,
      refetchOnWindowFocus: false,
    }
  );
  const navigate = useNavigate();
  const [cards, setCards] = useState<
    | {
        name: string;
        href: string;
        subtitle: string;
        icon: any;
        color: string;
        value: string;
      }[]
    | null
  >(null);

  useEffect(() => {
    if (user) {
      const cards = [];

      user.roles.includes(Role.Estudante) &&
        cards.push({
          name: "Pontos de correção",
          subtitle: "Pontos usados para enviar uma redação",
          color: "text-secondary",
          icon: InformationCircleIcon,
          value: (() => {
            if (user?.profile?.points == 1) {
              return user?.profile?.points + " ponto";
            } else if (user?.profile?.points > 1) {
              return user?.profile?.points + " pontos";
            }

            return "0 ponto";
          })(),
        });

      (user.roles.includes(Role.Professor) ||
        user.roles.includes(Role.Coordenador)) &&
        cards.push({
          name: "Textos enviados este mês",
          subtitle: "Textos enviados este mês pelos seus alunos",
          color: "text-secondary",
          icon: ArchiveIcon,
          value: (() => {
            if (textsMonthly && textsMonthly?.count == 1) {
              return textsMonthly?.count + " texto";
            } else if (textsMonthly && (textsMonthly?.count as number) > 1) {
              return textsMonthly?.count + " textos";
            }

            return "0 texto";
          })(),
        });

      (user.roles.includes(Role.Professor) ||
        user.roles.includes(Role.Coordenador)) &&
        cards.push({
          name: "Textos enviados esta semana",
          subtitle: "Textos enviados esta semana pelos seus alunos",
          color: "text-accent",
          icon: CalendarIcon,
          value: (() => {
            if (textsWeekly && textsWeekly?.count == 1) {
              return textsWeekly?.count + " texto";
            } else if (textsWeekly && (textsWeekly?.count as number) > 1) {
              return textsWeekly?.count + " textos";
            }

            return "0 texto";
          })(),
        });

      user.roles.includes(Role.Corretor) &&
        cards.push({
          name: "Textos enviados esta semana",
          subtitle: "Textos enviados esta semana pelos alunos",
          color: "text-accent",
          icon: CalendarIcon,
          value: (() => {
            if (correctorReport && correctorReport.textsSentWeekly == 1) {
              return correctorReport.textsSentWeekly + " texto";
            } else if (correctorReport && correctorReport.textsSentWeekly > 1) {
              return correctorReport.textsSentWeekly + " textos";
            }

            return "0 texto";
          })(),
        });

      user.roles.includes(Role.Corretor) &&
        cards.push({
          name: "Textos enviados este mês",
          subtitle: "Textos enviados este mês pelos alunos",
          color: "text-secondary",
          icon: CalendarIcon,
          value: (() => {
            if (correctorReport && correctorReport.textsSentMonthly == 1) {
              return correctorReport.textsSentMonthly + " texto";
            } else if (
              correctorReport &&
              correctorReport.textsSentMonthly > 1
            ) {
              return correctorReport.textsSentMonthly + " textos";
            }

            return "0 texto";
          })(),
        });

      user.roles.includes(Role.Corretor) &&
        cards.push({
          name: "Textos corrigidos esta semana",
          subtitle: "Textos já corrigidos esta semana por você",
          color: "text-error",
          icon: CalendarIcon,
          value: (() => {
            if (correctorReport && correctorReport.textsCorrectedWeekly == 1) {
              return correctorReport.textsCorrectedWeekly + " texto";
            } else if (
              correctorReport &&
              correctorReport.textsCorrectedWeekly > 1
            ) {
              return correctorReport.textsCorrectedWeekly + " textos";
            }

            return "0 texto";
          })(),
        });

      user.roles.includes(Role.Corretor) &&
        cards.push({
          name: "Textos para corrigir",
          subtitle: "Textos que ainda estão por corrigir",
          color: "text-success",
          icon: CalendarIcon,
          value: (() => {
            if (correctorReport && correctorReport.textsTocorrect == 1) {
              return correctorReport.textsTocorrect + " texto";
            } else if (correctorReport && correctorReport.textsTocorrect > 1) {
              return correctorReport.textsTocorrect + " textos";
            }

            return "0 texto";
          })(),
        });

      user.roles.includes(Role.Corretor) &&
        cards.push({
          name: "Total de textos já corrigidos",
          subtitle: "Quantidade total de textos já corrigidos por você",
          color: "text-accent",
          icon: CalendarIcon,
          value: (() => {
            if (
              correctorReport &&
              correctorReport.allTextsCorrectedByCorrector == 1
            ) {
              return correctorReport.allTextsCorrectedByCorrector + " texto";
            } else if (
              correctorReport &&
              correctorReport.allTextsCorrectedByCorrector > 1
            ) {
              return correctorReport.allTextsCorrectedByCorrector + " textos";
            }

            return "0 texto";
          })(),
        });

      setCards(
        Object.assign(
          [],
          [
            {
              name: "Tempo restante na plataforma",
              href: "/app/planos",
              subtitle: "Dias restantes para acessar a plataforma",
              color: "text-primary",
              icon: ClockIcon,
              value: (() => {
                if (!user?.access_until) {
                  return "Nenhum plano ativo ainda";
                }
                const now = new Date();
                const end = new Date(user?.access_until);
                const diff = end.getTime() - now.getTime();
                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                return days + " dias";
              })(),
            },
            ...cards,
            // {
            //   name: "Vídeos já assistidos",
            //   href: "/app/videos",
            //   subtitle: "Assistir vídeos",
            //   icon: CursorClickIcon,
            //   // value: user.videos_watched.length + " vídeos",
            //   value: "7" + " vídeos",
            // },
          ]
        )
      );
    }
  }, [user, textsWeekly, textsMonthly, correctorReport]);

  return (
    <>
      <HeaderDashboard />

      <div className="mt-8">
        <div className="flex justify-between flex-wrap items-center max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {cards?.map((card) => (
            <CardInfo
              color={card.color}
              key={card.name}
              name={card.name}
              icon={card.icon}
              value={card.value}
              subtitle={card.subtitle}
              href={card.href}
              onClick={() => navigate(card.href)}
            />
          ))}
        </div>
      </div>
      <div className="mt-8">
        <div className="flex flex-row flex-wrap gap-4 px-4 sm:px-6 lg:px-8 xl:px-10 items-center justify-between">
          {anualPerformance && anualPerformance?.data && (
            <AreaGraph
              title={
                user?.roles?.includes(Role.Estudante)
                  ? "Suas notas ao longo do tempo"
                  : "Notas ao longo do tempo"
              }
              max={1000}
              min={0}
              colorId="colorUv"
              color={"#8884d8"}
              data={anualPerformance.data}
            />
          )}
          {competencesAvg && competencesAvg?.data && (
            <BarGraph
              title={
                user?.roles?.includes(Role.Estudante)
                  ? "Nota média em cada competência"
                  : "Média em cada competência"
              }
              max={200}
              min={0}
              color={"#ff86b7"}
              data={competencesAvg.data}
            />
          )}
        </div>
      </div>

      {user?.force_new_password && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Defina uma nova senha!</h3>
            <p className="py-4">
              Por sua segurança sugerimos que você altere sua senha atual!
            </p>
            <div className="col-span-4 sm:col-span-2">
              <InputText
                type="password"
                name="new-pass"
                id="new-pass"
                autoComplete="disabled"
                label="Senha"
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
              />
            </div>

            <div className="col-span-4 sm:col-span-2">
              <InputText
                type="password"
                name="new-pass-confirm"
                id="new-pass-confirm"
                autoComplete="disabled"
                label="Confirmar nova senha"
                value={newPassConfirm}
                onChange={(e) => setNewPassConfirm(e.target.value)}
              />
            </div>
            <div className="modal-action">
              <label
                className="btn"
                onClick={async () => {
                  if (newPass !== newPassConfirm) {
                    notification("As senhas precisam ser iguais!", {
                      type: "error",
                    });
                  } else {
                    await profileAPI.update({
                      body: {
                        password: newPass,
                      },
                    });

                    notification("Senhas alteradas com sucesso!", {
                      type: "success",
                    });

                    setUser({ ...user, force_new_password: false });
                  }
                }}
              >
                Alterar
              </label>
            </div>
          </div>
        </div>
      )}

      {user && user.profile && user.profile.gifts && user?.profile?.gifts[0] ? (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">
              {user?.profile.gifts[0]?.title}
            </h3>
            <p className="py-4">{user?.profile.gifts[0]?.description}</p>
            <div className="modal-action">
              <label
                className="btn"
                onClick={async () => {
                  await giftAPI.view(user?.profile.gifts[0]?.id);

                  setUser({ ...user, profile: { ...user.profile, gifts: [] } });
                }}
              >
                Entendi!
              </label>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DashboardPage;
