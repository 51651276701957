import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { studentAPI } from "../../../../api/student.api";
import Table from "../../../../components/Table";

const TextsUserPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: texts } = useQuery(
    ["user-texts", id],
    () => studentAPI.findAllTexts({ id: id! }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="p-8">
      <Table
        title="Redações"
        data={
          texts
            ? texts?.texts?.map((t) => ({
                id: t.id,
                status: t.status,
                theme: t.theme?.title,
                created_at: t.created_at,
              }))
            : []
        }
        fields={[
          {
            editable: false,
            field: "theme",
            name: "Tema",
            type: "text",
          },
          {
            editable: false,
            field: "status",
            name: "Status",
            type: "text",
          },
          {
            editable: false,
            field: "created_at",
            name: "Criado em",
            type: "date",
          },
        ]}
        actions={[
          {
            element: ({ id }: any) => (
              <button
                className="text-blue-500 hover:text-blue-600"
                onClick={() => navigate("/app/escrever/" + id)}
              >
                Acessar
              </button>
            ),
          },
        ]}
        onSave={() => {}}
      />
    </div>
  );
};

export default TextsUserPage;
