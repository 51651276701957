import React from "react";

interface Props {
  label: string;
  accept: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputFile = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <div className="w-full col-span-6 sm:col-span-3">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
        {props.label}
      </label>
      <input
        ref={ref}
        // onChange={(e) => onChange(e)}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1.5 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white dark:border-gray-600"
        type="file"
        accept={props.accept}
      />
    </div>
  );
});

export default InputFile;
