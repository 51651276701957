import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import CardImage from "../../../../components/VideoCard";
import { textAPI } from "../../../../api/text.api";
import { useAuth } from "../../../../utils/context/auth";
import { classNames } from "../../../../utils/functions/ClassNames";
import Loading from "../../../../components/Loading";

const DefaultGridCover: React.FC = ({ children }) => {
  return (
    <div className="mt-8">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <ul
          role="list"
          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {children}
        </ul>
      </div>
    </div>
  );
};

interface TabInterface {
  name: string;
  status: string;
}

const TextsPage = () => {
  const { notification } = useAuth();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState("Criado");
  const { data: texts, status } = useQuery(
    "my-texts",
    () => textAPI.findAll(),
    {
      // cacheTime: 1000 * 60,
      // staleTime: 1000 * 60,
      refetchOnWindowFocus: false,
    }
  );
  const tabs: TabInterface[] = [
    { name: "Criadas", status: "Criado" },
    { name: "Enviados", status: "Enviado" },
    { name: "Em correção", status: "Corrigindo" },
    { name: "Corrigidas", status: "Corrigido" },
    { name: "Anuladas", status: "Anulado" },
    { name: "Devolvidas", status: "Devolvido" },
    { name: "Canceladas", status: "Cancelado" },
  ];

  return (
    <>
      <div className="pb-5 sm:pb-0 p-6 mb-4 bg-base-100">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
          Minhas Redações
        </h3>
        <div className="mt-3 sm:mt-4">
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              defaultValue={currentPage}
              value={currentPage}
              onChange={(e) => setCurrentPage(e?.target?.value)}
            >
              {tabs.map((tab) => (
                <option value={tab.status} key={tab.name}>
                  {tab.name} (
                  {texts &&
                    texts?.text?.filter((text) => text.status === tab.status)
                      .length}
                  )
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <p
                  key={tab.name}
                  className={classNames(
                    currentPage === tab.status
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                  )}
                  aria-current={currentPage === tab.status ? "page" : undefined}
                  onClick={(e) => setCurrentPage(tab.status)}
                >
                  {tab.name} (
                  {texts &&
                    texts?.text?.filter((text) => text.status === tab.status)
                      .length}
                  )
                </p>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <DefaultGridCover>
        {status === "loading" && <Loading />}
        {texts &&
          texts?.text?.map((text) =>
            text.status === currentPage ? (
              <CardImage
                key={text.id}
                onClick={() => navigate(`/app/redacao/${text.id}`)}
                image={text?.theme?.image}
                title={text?.theme?.title}
                id={text.id}
                buttonText="Acessar"
              />
            ) : (
              <></>
            )
          )}
      </DefaultGridCover>
    </>
  );
};

export default TextsPage;
