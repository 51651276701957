import { API_URL } from "../utils/api";
import { ErrorInterface } from "../utils/interfaces/error.interface";
import { Area, User } from "../utils/interfaces/models";
import { OnlyIDInterface } from "../utils/interfaces/only-id.interface";

interface CreateAreaBody {
  x: Number;
  y: Number;
  width: Number;
  height: Number;
}

interface CreateAreaInterface {
  text_id: string;
  body: CreateAreaBody;
}

interface CreateAreaResponseInterface extends ErrorInterface {
  area: Area;
}

interface DeleteAreaResponseInterface extends ErrorInterface {
  area: Area;
}

export const areaAPI = {
  create: async (
    data: CreateAreaInterface
  ): Promise<CreateAreaResponseInterface> => {
    return await fetch(`${API_URL}/area/${data.text_id}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.body),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { area: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  delete: async (
    data: OnlyIDInterface
  ): Promise<DeleteAreaResponseInterface> => {
    return await fetch(`${API_URL}/area/${data.id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { area: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },
};
