import React from "react";
import { useQuery } from "react-query";
import moment from "moment";
// import { feeAPI } from "../../../../../api/fee.api";

const PaymentsCorrectorPage = () => {
  // const { data: payments } = useQuery("my-payments", () => feeAPI.get(), {
  //   cacheTime: 1000 * 60,
  //   staleTime: 1000 * 60,
  //   refetchOnWindowFocus: false,
  // });

  return (
    <>
      {/* <div className="mt-8">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <section aria-labelledby="billing-history-heading">
            <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden dark:bg-gray-900">
              <div className="px-4 sm:px-6">
                <h2
                  id="billing-history-heading"
                  className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                >
                  Pagamentos Recebidos ( Valor total recebido: R${" "}
                  {payments &&
                    payments?.data?.reduce(
                      (acc, curr) => Number(acc) + Number(curr.total_payed),
                      0
                    )}{" "}
                  )
                </h2>
              </div>
              <div className="mt-6 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden border-t border-gray-200 dark:border-gray-700">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                          <tr>
                            {[
                              "Valor Pago",
                              "Quantidade de textos",
                              "Comprovante",
                              "Data de pagamento",
                            ].map((e, i) => (
                              <th
                                key={i}
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                {e}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700">
                          {payments &&
                            payments?.data?.map((payment) => (
                              <tr key={payment?.id}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                  {payment?.total_payed}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                  {payment?.texts.length}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-500 dark:text-blue-400">
                                  <a
                                    href={payment?.image.url}
                                    rel="noreferrer noopener"
                                    target="_blank"
                                  >
                                    Imagem do comprovante
                                  </a>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                                  <time dateTime={payment?.created_at}>
                                    {moment(payment?.created_at).format(
                                      "DD/MM/YYYY HH:mm"
                                    )}
                                  </time>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
};

export default PaymentsCorrectorPage;
