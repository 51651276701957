import React, { useState } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";
import { useAuth } from "../../../../../utils/context/auth";
import Button from "../../../../../components/Button";
import InputText from "../../../../../components/InputText";
import Select from "../../../../../components/Select";
import DatePicker from "../../../../../components/DatePicker";
import { teamAPI } from "../../../../../api/team.api";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../../../components/Table";
import { ChartBarIcon } from "@heroicons/react/solid";
import { CollectionIcon } from "@heroicons/react/outline";
import { studentAPI } from "../../../../../api/student.api";

const StudentsCoordinatorPage: React.FC = () => {
  const [filter, setFilter] = useState({ name: "", team: "" });
  const { data: teams } = useQuery("school-teams", () => teamAPI.findAll(), {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 5,
    staleTime: 1000 * 5,
  });
  const {
    data: students,
    refetch,
    isLoading,
  } = useQuery(["students", filter], () => studentAPI.findAll(filter), {
    enabled: false,
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 0,
    staleTime: 1000 * 0,
  });
  const { data: classes } = useQuery("classes", teamAPI.findAll, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 0,
    staleTime: 1000 * 0,
  });
  const { notification } = useAuth();
  const [access_until, setAccess_until] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [classId, setClassId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const createSchool = async () => {
    if (password !== passwordConfirm) {
      return notification("As senhas inseridas devem ser iguais!", {
        type: "warning",
      });
    }

    if (password.trim() === "") {
      return notification("A senha não pode ser vazia!", {
        type: "warning",
      });
    }

    if (password.length < 8) {
      return notification("A deve ter no minimo 8 caracteres!", {
        type: "warning",
      });
    }

    setLoading(true);
    const { error, user, message } = await studentAPI.create({
      name,
      access_until: new Date(access_until),
      email,
      password,
      force_new_password: true,
      team_id: classId,
      verified: true,
    });

    setLoading(false);
    if (error && message) {
      return notification(message ?? "Erro ao criar usuário!", {
        type: "error",
      });
    }

    refetch();

    setAccess_until("");
    setEmail("");
    setName("");
    setPassword("");
    setPasswordConfirm("");
    setName("");
    setClassId("");

    return notification("Usuário criado com sucesso", {
      type: "success",
    });
  };

  const update = async (data: any) => {
    const { id, ...body } = data;

    const { error, message } = await studentAPI.update({ id, body });

    if (error && message) {
      return notification(message, {
        type: "error",
      });
    }

    refetch();
    return notification("Usuário atualizado com sucesso", {
      type: "success",
    });
  };

  const findWithFilter = () => {
    refetch();
  };

  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 px-4 space-y-8 lg:px-8">
          <section aria-labelledby="payment-details-heading">
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-base-100 py-6 px-4 sm:p-6 ">
                  <div>
                    <h2
                      id="payment-details-heading"
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                    >
                      Cadastrar um novo aluno
                    </h2>
                  </div>

                  <div className="mt-6 grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="text"
                        label="Nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="email"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <DatePicker
                        label="Acesso até"
                        onChange={(e) =>
                          setAccess_until(
                            moment(e.target.value).toLocaleString()
                          )
                        }
                        value={moment(access_until).format("YYYY-MM-DD")}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="password"
                        autoComplete="disabled"
                        label="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="password"
                        autoComplete="disabled"
                        label="Confirmar senha"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <Select
                        label="Turma"
                        value={classId}
                        onChange={(e) => setClassId(e.target.value)}
                      >
                        <option>Selecione uma turma</option>
                        {classes &&
                          classes?.team?.map((e) => (
                            <option key={e.id} value={e.id}>
                              {e.name}
                            </option>
                          ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
                  <Button
                    type="button"
                    onClick={() => createSchool()}
                    loading={loading}
                    label={"Criar"}
                    color="primary"
                  />
                </div>
              </div>
            </form>
          </section>

          <section aria-labelledby="billing-history-heading">
            <Table
              title="Alunos"
              find={() => findWithFilter()}
              filterLoading={isLoading}
              filter={true}
              data={
                students
                  ? students?.user?.map((s) => ({
                      id: s.id,
                      name: s.profile?.name,
                      email: s.profile?.user?.email,
                      team: s.team?.name,
                      access_until: s.profile?.user?.access_until,
                    }))
                  : []
              }
              fields={[
                {
                  editable: true,
                  field: "name",
                  name: "Nome",
                  type: "text",
                },
                {
                  editable: true,
                  field: "email",
                  name: "Email",
                  type: "text",
                },
                {
                  editable: true,
                  field: "team",
                  name: "Turma",
                  type: "select",
                  value: "team_id",
                  ...(teams?.team && {
                    selectOptions: teams?.team?.map((e) => ({
                      value: e.id,
                      name: e.name,
                    })),
                  }),
                },
                {
                  editable: true,
                  field: "access_until",
                  name: "Acesso até",
                  type: "date",
                },
              ]}
              actions={[
                {
                  element: ({ id }: any) => (
                    <Link
                      className="text-blue-500 hover:text-blue-600"
                      // onClick={() => navigate("/app/relatorio/usuario/" + id)}
                      to={"/app/relatorio/usuario/" + id}
                    >
                      <ChartBarIcon color="#0099ff" width={18} height={18} />
                    </Link>
                  ),
                },
                {
                  element: ({ id }: any) => (
                    <Link
                      className="text-blue-500 hover:text-blue-600"
                      // onClick={() => navigate("/app/redacoes/usuario/" + id)}
                      to={"/app/redacoes/usuario/" + id}
                    >
                      <CollectionIcon color="#0099ff" width={18} height={18} />
                    </Link>
                  ),
                },
              ]}
              onSave={(d) => update(d)}
            >
              <div className="grid grid-cols-4 gap-6 mb-4">
                <div className="col-span-4 sm:col-span-2">
                  <InputText
                    type="text"
                    label="Nome"
                    value={filter.name}
                    onChange={(e) =>
                      setFilter({ ...filter, name: e.target.value })
                    }
                  />
                </div>

                <div className="col-span-4 sm:col-span-2">
                  <Select
                    label="Turma"
                    value={filter.team}
                    onChange={(e) =>
                      setFilter({ ...filter, team: e.target.value })
                    }
                  >
                    <option value="">Todas</option>
                    {classes &&
                      classes?.team?.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
            </Table>
          </section>
        </main>
      </div>
    </>
  );
};

export default StudentsCoordinatorPage;
