import React, { useState } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";
import { useAuth } from "../../../../../utils/context/auth";
import Button from "../../../../../components/Button";
import InputText from "../../../../../components/InputText";
import { schoolAPI } from "../../../../../api/school.api";
import DatePicker from "../../../../../components/DatePicker";
import Table from "../../../../../components/Table";
import { profileAPI } from "../../../../../api/profile.api";
import Select from "../../../../../components/Select";
import { Role } from "../../../../../utils/interfaces/models";
import { studentAPI } from "../../../../../api/student.api";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { authAPI } from "../../../../../api/auth.api";

const UsersAdminPage: React.FC = () => {
  const { data: profiles, refetch } = useQuery("users", profileAPI.findAll, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 0,
    staleTime: 1000 * 0,
  });
  const { data: schools } = useQuery("schools", schoolAPI.findAll, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 0,
    staleTime: 1000 * 0,
  });
  const { notification } = useAuth();
  const [access_until, setAccess_until] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState<Role>(Role.Coordenador);
  const [school, setSchool] = useState("");

  const [loading, setLoading] = useState(false);

  const createUser = async () => {
    setLoading(true);
    const { error, message } = await profileAPI.create({
      access_until: new Date(access_until),
      name,
      email,
      password,
      role,
      ...(school && { school }),
    });

    setLoading(false);
    if (error && message) {
      return notification(message, {
        type: "error",
      });
    }

    refetch();

    setAccess_until("");
    setName("");
    setEmail("");
    setPassword("");
    setRole(Role.Coordenador);
    setSchool("");

    return notification("Usuário criado com sucesso!", {
      type: "success",
    });
  };

  const edit = async (obj: any) => {
    const { id, ...body } = obj;
    const { message, error } = await profileAPI.admUpdate({
      id,
      body: {
        ...(body.access_until && { access_until: new Date(body.access_until) }),
        ...(body.limit_users && { limit_users: Number(body.limit_users) }),
        ...(body.name && { name: body.name }),
        ...(body.points && {
          points: Number(body.points),
        }),
      },
    });

    if (error && message) {
      return notification(message, {
        type: "error",
      });
    }

    refetch();
    return notification("Usuário atualizado com sucesso!", {
      type: "success",
    });
  };

  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 px-4 space-y-8 lg:px-8">
          <section>
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="py-6 px-4 sm:p-6 bg-base-100">
                  <div>
                    <h2
                      id="payment-details-heading"
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                    >
                      Cadastrar um novo usuário
                    </h2>
                  </div>

                  <div className="mt-6 grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="text"
                        label="Nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="text"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="password"
                        label="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <Select
                        onChange={(e) => setRole(e.target.value as Role)}
                        value={role}
                        label="Função"
                        defaultValue={Role.Coordenador}
                      >
                        <option value={Role.Coordenador}>
                          {Role.Coordenador}
                        </option>
                        <option value={Role.Corretor}>{Role.Corretor}</option>
                      </Select>
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <Select
                        onChange={(e) => setSchool(e.target.value)}
                        value={school}
                        label="Escola"
                      >
                        <option value="">Selecione uma escola</option>
                        {schools?.school?.map((e) => (
                          <option value={e.id}>{e.name}</option>
                        ))}
                      </Select>
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <DatePicker
                        label="Acesso até"
                        onChange={(e) =>
                          setAccess_until(
                            moment(e.target.value).toLocaleString()
                          )
                        }
                        value={moment(access_until).format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
                  <Button
                    type="button"
                    onClick={() => createUser()}
                    loading={loading}
                    label={"Criar"}
                    color="primary"
                  />
                </div>
              </div>
            </form>
          </section>

          <section aria-labelledby="billing-history-heading">
            <Table
              title="Usuários"
              data={
                profiles
                  ? profiles?.profile?.map((s) => ({
                      id: s.id,
                      name: s.name,
                      email: s.user.email,
                      points: s.points,
                      access_until: s.user.access_until,
                      school: s.school?.name,
                    }))
                  : []
              }
              fields={[
                {
                  editable: true,
                  field: "name",
                  name: "Nome",
                  type: "text",
                },
                {
                  editable: true,
                  field: "email",
                  name: "Email",
                  type: "text",
                },
                {
                  editable: true,
                  field: "points",
                  name: "Pontos",
                  type: "number",
                },
                {
                  editable: true,
                  field: "access_until",
                  name: "Acesso até",
                  type: "date",
                },
                {
                  editable: false,
                  field: "school",
                  name: "Escola",
                  type: "text",
                },
              ]}
              actions={[
                {
                  element: ({ id }: any) => (
                    <button
                      className="text-blue-500 hover:text-blue-600"
                      onClick={async () => {
                        await authAPI.loginas(id);
                      }}
                    >
                      <ArrowRightIcon color="#0099ff" width={18} height={18} />
                    </button>
                  ),
                },
              ]}
              onSave={(d) => edit(d)}
            />
          </section>
        </main>
      </div>
    </>
  );
};

export default UsersAdminPage;
