import React from "react";
import Loading from "../Loading";

interface Props {
  id?: string;
  title?: string;
  subTitle?: string;
  image?: {
    url?: string;
  };
  loading?: boolean;
  onClick?: () => void;
  buttonText?: string;
}

const CardImage: React.FC<Props> = (props) => {
  if (props.loading) {
    return (
      <li className="relative shadow-md rounded-md bg-white cursor-pointer dark:bg-gray-900">
        <div className="border shadow rounded-md w-full mx-auto dark:border-gray-800">
          <div className="animate-pulse flex flex-col">
            <div className="bg-slate-200 h-[150px] flex items-center justify-center w-full rounded-md rounded-b-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 dark:focus-within:ring-offset-gray-600">
              <Loading colored />
            </div>
            <div className="space-y-3 w-full p-4 ">
              <div className="h-2 bg-slate-200 rounded"></div>
              <div className="h-2 bg-slate-200 rounded"></div>
            </div>
          </div>
        </div>
      </li>
    );
  }
  return (
    <li className="relative cursor-pointer" onClick={props.onClick}>
      <div className="card card-compact w-full bg-base-100 shadow-xl">
        <figure>
          <img src={props?.image?.url} alt="Shoes" />
        </figure>
        <div className="card-body">
          <h2 className="card-title">{props.title}</h2>
          <p>{props.subTitle}</p>
        </div>
      </div>
    </li>
  );
};

export default CardImage;
