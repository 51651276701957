import {
  CheckCircleIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { profileAPI } from "../../api/profile.api";
import { userAPI } from "../../api/user.api";
import { useAuth } from "../../utils/context/auth";
import { User } from "../../utils/interfaces/models";

interface Props {
  user?: User;
}

const HeaderDashboard: React.FC<Props> = ({ user: userProp }) => {
  const { user, notification, setUser } = useAuth();
  const [counter, setCounter] = useState<number | null>(null);

  useEffect(() => {
    if (counter) {
      if (counter === 0) {
        return setCounter(null);
      }
      const interval = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [counter]);

  const resendEmail = async () => {
    const { error, message } = await userAPI.resendRecovery();
    if (error) {
      return notification(message, { type: "warning" });
    }
    setCounter(30);
    return notification(message, {
      type: "success",
    });
  };

  const uploadProfile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files && e.target?.files[0]) {
      const file_name = e.target?.files[0]?.name;

      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      reader.onload = async (el: ProgressEvent<FileReader>) => {
        const lastImage = user?.profile.image;
        setUser({
          ...user,
          image: {
            file_name: file_name,
            url: el.target?.result,
          },
        });

        if (e.target?.files && e.target?.files[0]) {
          const { error, message } = await profileAPI.updateImage({
            image: e.target.files[0],
          });

          if (error && message) {
            setUser({
              ...user,
              image: lastImage,
            });

            notification(message ?? "Erro ao fazer upload da imagem", {
              type: "warning",
            });
          } else {
            notification("Imagem atualizada com sucesso!", {
              type: "success",
            });
          }
        }
      };
    }
  };

  return (
    <div className="shadow bg-base-100">
      <div className="px-6 sm:px-4 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200 dark:border-gray-800">
          <div className="flex-1 min-w-0">
            <div className="flex items-center">
              <div className=" grid-cols-1 h-16 w-16 sm:grid hidden">
                <img
                  className="hidden h-16 w-16 rounded-full sm:block absolute"
                  src={
                    userProp?.profile?.image?.url ||
                    user?.profile?.image?.url ||
                    "images/avatar.webp"
                  }
                  alt=""
                />
                {!userProp && (
                  <div className="sm:block hidden">
                    <input
                      onChange={(e) => uploadProfile(e)}
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      accept="image/png,image/jpeg,image/jpg,image/webp"
                      style={{ zIndex: 10 }}
                      className="w-16 h-16 absolute indent-96 hover:bg-gray-200 rounded-full hover:bg-opacity-50 hover:cursor-pointer"
                    />
                    {!user?.profile?.image?.url && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-16 w-16 absolute p-4 text-gray-800 rounded-full hover:cursor-pointer"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </div>

              <div>
                <div className="flex items-center">
                  <div className="relative">
                    <img
                      className="h-16 w-16 rounded-full sm:hidden "
                      src={
                        userProp?.profile?.image?.url ||
                        user?.profile?.image?.url ||
                        "images/avatar.webp"
                      }
                      alt=""
                    />
                    {!userProp && (
                      <div className="sm:hidden absolute bottom-[65px]">
                        <input
                          onChange={(e) => uploadProfile(e)}
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          accept="image/png,image/jpeg,image/jpg,image/webp"
                          style={{ zIndex: 10 }}
                          className="w-16 h-16 absolute indent-96 hover:bg-gray-200 rounded-full hover:bg-opacity-50 hover:cursor-pointer"
                        />
                        {!user?.profile?.image?.url && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-16 w-16 absolute p-4 text-gray-800 rounded-full hover:cursor-pointer"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </div>
                    )}
                  </div>
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate dark:text-gray-200">
                    {!userProp &&
                      ((): string => {
                        const hour = new Date().getHours();
                        if (hour >= 6 && hour < 12) {
                          return "Bom dia, ";
                        }
                        if (hour >= 12 && hour < 18) {
                          return "Boa tarde, ";
                        }

                        return "Boa noite, ";
                      })()}
                    {userProp?.profile?.name || user?.profile?.name}
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dt className="sr-only">Tipo da conta</dt>
                  <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                    <UserIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {userProp ? userProp?.roles[0] : user?.roles[0]}
                  </dd>
                  <dt className="sr-only">Status da conta</dt>
                  <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                    {userProp &&
                      (userProp?.verified ? (
                        <CheckCircleIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <XCircleIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      ))}
                    {!userProp &&
                      (user?.verified ? (
                        <CheckCircleIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <XCircleIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      ))}
                    {userProp &&
                      (userProp?.verified
                        ? "Conta verificada"
                        : "Conta não verificada")}
                    {!userProp &&
                      (user?.verified
                        ? "Conta verificada"
                        : "Conta não verificada")}
                    {!userProp && !user?.verified && (
                      <p
                        onClick={
                          counter && counter > 0
                            ? () => {}
                            : () => resendEmail()
                        }
                        className="text-blue-600 text-xs pl-2 cursor-pointer"
                      >
                        {counter && counter > 0
                          ? "Enviar novamente em: " + counter + " segundos"
                          : "Reenviar e-mail de confirmação"}
                      </p>
                    )}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderDashboard;
