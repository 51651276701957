import { API_URL } from "../utils/api";
import { ErrorInterface } from "../utils/interfaces/error.interface";
import { Team, User } from "../utils/interfaces/models";

interface AnualPerformance {
  total: Number;
  created_at: string;
}

interface AnualPerformanceResponseInterface extends ErrorInterface {
  data: AnualPerformance[];
}

interface CompetencesAvg {
  comp1: Number;
  comp2: Number;
  comp3: Number;
  comp4: Number;
  comp5: Number;
}

interface CompetencesAvgResponseInterface extends ErrorInterface {
  data: CompetencesAvg[];
}

interface CountResponseInterface extends ErrorInterface {
  count: Number;
}

type AnualPerformanceType = {
  total: number;
  created_at: Date;
};

type AvgCompetenceType = {
  comp1: number;
  comp2: number;
  comp3: number;
  comp4: number;
  comp5: number;
};

type UserReportResponse = {
  anual: AnualPerformanceType[];
  avgCompetence: AvgCompetenceType;
  textsSentMonthly: number;
  textsSentWeekly: number;
  user: User;
};

type TeamReportResponse = {
  anual: AnualPerformanceType[];
  avgCompetence: AvgCompetenceType;
  textsSentMonthly: number;
  textsSentWeekly: number;
  team: Team;
};

type CorrectorReport = {
  textsSentMonthly: number;
  textsSentWeekly: number;
  textsTocorrect: number;
  textsCorrectedWeekly: number;
  allTextsCorrectedByCorrector: number;
};

type AdminReport = {
  loginsToday: number;
  loginsWeekly: number;
  loginsMonthly: number;
};

export const reportAPI = {
  anualPerformance: async (): Promise<AnualPerformanceResponseInterface> => {
    return await fetch(`${API_URL}/reports/performance/anual`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  competencesAvg: async (): Promise<CompetencesAvgResponseInterface> => {
    return await fetch(`${API_URL}/reports/competences/avg`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  textsSentMonthly: async (): Promise<CountResponseInterface> => {
    return await fetch(`${API_URL}/reports/texts/sent/monthly`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { count: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  textsSentWeekly: async (): Promise<CountResponseInterface> => {
    return await fetch(`${API_URL}/reports/texts/sent/weekly`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { count: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  userReport: async (id: string): Promise<UserReportResponse> => {
    return await fetch(`${API_URL}/reports/user/${id}`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { ...data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  teamReport: async (id: string): Promise<TeamReportResponse> => {
    return await fetch(`${API_URL}/reports/team/${id}`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { ...data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  correctorReport: async (): Promise<CorrectorReport> => {
    return await fetch(`${API_URL}/reports/corrector/report`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { ...data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  adminReport: async (): Promise<AdminReport> => {
    return await fetch(`${API_URL}/reports/admin/report`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { ...data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },
};
