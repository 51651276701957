import React, { useEffect, useState } from "react";
import { useAuth } from "../../../utils/context/auth";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "../../../components/Modal";
import InputText from "../../../components/InputText";
import Button from "../../../components/Button";
import { userAPI } from "../../../api/user.api";

const LoginPage: React.FC = () => {
  const { login, notification } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [recoveryStep, setRecoveryStep] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [pass, setPass] = useState<string>("");
  const [passConfirm, setPassConfirm] = useState<string>("");
  const [recoveryCode, setRecoveryCode] = useState<string>("");
  const [emailRecovery, setEmailRecovery] = useState<string>("");
  const [passRecovery, setPassRecovery] = useState<string>("");
  const [loadingRecovery, setLoadingRecovery] = useState<boolean>(false);
  const [subdomain, setSubdomain] = useState<string>("");

  useEffect(() => {
    const subdomain = window.location.hostname.split(".")[0];

    setSubdomain(subdomain);

    if (
      document.location.search.includes("recovery_token") &&
      document.location.search.includes("recoveryStep")
    ) {
      const recoveryToken = document.location.search
        .split("=")[1]
        .split("&")[0];
      const recoveryStep = document.location.search.split("=")[2];

      setRecoveryStep(Number(recoveryStep));
      setModalOpen(true);
      setRecoveryCode(recoveryToken);
    }
  }, []);

  const RECOVERY_STEPS = [
    {
      title: "Digite seu e-mail",
      nextText: "Enviar",
      next: async () => {
        if (emailRecovery.trim() === "") {
          return notification("Erro. Preencha todos os dados.", {
            type: "warning",
          });
        }

        setLoadingRecovery(true);

        const { message, error } = await userAPI.forgotPass({
          email: emailRecovery,
        });

        if (error) {
          return notification(message, {
            type: "warning",
          });
        }

        notification(message, {
          type: "success",
        });

        setRecoveryStep(0);
        setEmailRecovery("");
        setPassRecovery("");
        setPassConfirm("");
        setRecoveryCode("");
        setLoadingRecovery(false);
        setModalOpen(false);

        return;
      },
    },
    {
      title: "Digita e confirme a nova senha",
      nextText: "Enviar",
      next: async () => {
        if (passRecovery.trim() === "" || passConfirm.trim() === "") {
          return notification("Erro. Preencha todos os dados.", {
            type: "warning",
          });
        }

        if (passRecovery !== passConfirm) {
          return notification("Erro. As senhas não são iguais.", {
            type: "warning",
          });
        }

        setLoadingRecovery(true);

        const { error, message } = await userAPI.recoveryPass({
          recovery_token: recoveryCode,
          password: passRecovery,
        });

        if (error) {
          return notification(message, {
            type: "warning",
          });
        }

        setModalOpen(false);
        notification(message, {
          type: "success",
        });

        setRecoveryStep(0);
        setEmailRecovery("");
        setPassRecovery("");
        setPassConfirm("");
        setRecoveryCode("");
        setLoadingRecovery(false);
        setModalOpen(false);

        return;
      },
    },
  ];

  const handleLogin = async () => {
    setLoading(true);

    await login(email, pass);

    setLoading(false);
  };

  return (
    <div className="min-h-screen flex dark:bg-gray-900">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="flex flex-col items-center">
            <img
              className="h-48 w-auto"
              src={
                subdomain === "escola"
                  ? "images/seulogoaqui.png"
                  : subdomain === "coc"
                  ? "images/logos_coc/logo1.png"
                  : "images/logos_betania/vertical_on_transparent_by_logaster.png"
              }
              alt="logo"
            />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-gray-200">
              Bem-vindo
            </h2>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              Faça login para continuar
            </p>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form
                className="space-y-6"
                onSubmit={(e) => {
                  e.preventDefault();

                  handleLogin();
                }}
              >
                <InputText
                  type="email"
                  name="email-address"
                  id="email-address"
                  autoComplete="email"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <InputText
                  type="password"
                  name="pass-recovery"
                  id="pass-recovery"
                  autoComplete="disabled"
                  label="Senha"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                />

                <div className="flex items-center justify-between">
                  <div className="flex items-center"></div>

                  <div className="text-sm">
                    <p
                      onClick={() => setModalOpen(true)}
                      className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
                    >
                      Esqueceu sua senha?
                    </p>
                  </div>
                </div>

                <div>
                  <Button
                    disabled={loading}
                    version="block"
                    color="primary"
                    type={"submit"}
                    label="Entrar"
                    loading={loading}
                    onClick={() => handleLogin()}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="images/auth_page_image.webp"
          alt=""
        />
      </div>
      <Modal isOpen={modalOpen}>
        <ModalHeader
          default
          title="Recuperação de senha"
          onClose={() => {
            setEmailRecovery("");
            setPassRecovery("");
            setPassConfirm("");
            setRecoveryCode("");
            setLoadingRecovery(false);
            setModalOpen(false);
            setRecoveryStep(0);
          }}
        />
        <ModalBody>
          <form action="#" method="POST">
            <div className="bg-transparent  py-0 px-2 space-y-2 sm:p-0">
              <div>
                <p className="mt-0 text-sm text-gray-500 dark:text-white mb-8">
                  {RECOVERY_STEPS[recoveryStep].title}
                </p>
              </div>

              {recoveryStep === 0 && (
                <InputText
                  type="email"
                  name="email-address"
                  id="email-address"
                  autoComplete="email"
                  label="Email"
                  value={emailRecovery}
                  onChange={(e) => setEmailRecovery(e.target.value)}
                />
              )}

              {recoveryStep === 1 && (
                <>
                  <InputText
                    type="password"
                    name="pass-recovery"
                    id="pass-recovery"
                    autoComplete="disabled"
                    label="Senha"
                    value={passRecovery}
                    onChange={(e) => setPassRecovery(e.target.value)}
                  />

                  <InputText
                    type="password"
                    name="pass-confirm"
                    id="pass-confirm"
                    autoComplete="disabled"
                    label="Confirmar senha"
                    value={passConfirm}
                    onChange={(e) => setPassConfirm(e.target.value)}
                  />
                </>
              )}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          {RECOVERY_STEPS[recoveryStep].nextText && (
            <Button
              disabled={loadingRecovery}
              color="primary"
              loading={loadingRecovery}
              label={RECOVERY_STEPS[recoveryStep].nextText || ""}
              onClick={() => RECOVERY_STEPS[recoveryStep].next()}
            />
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LoginPage;
