import React from "react";
import { classNames } from "../../utils/functions/ClassNames";

interface Props {
  type?: string;
  label?: string;
  value?: string;
  autoComplete?: string;
  placeholder?: string;
  defaultValue?: string;
  className?: string;
  name?: string;
  disabled?: boolean;
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select: React.FC<Props> = ({ children, ...props }) => {
  return (
    <div className="form-control w-full">
      <label className="label">
        <span className="label-text">{props.label}</span>
      </label>
      <select
        className="select select-bordered"
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        name={props.name}
        id={props.id || props.name}
        value={props.value}
        onChange={props.onChange}
      >
        {children}
      </select>
    </div>
  );
};

export default Select;
