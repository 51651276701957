import { API_URL } from "../utils/api";
import { ErrorInterface } from "../utils/interfaces/error.interface";
import { Student, Text } from "../utils/interfaces/models";
import { OnlyIDInterface } from "../utils/interfaces/only-id.interface";
import { ObjectToQueryString } from "../utils/objectToQueryString";

interface CreateInterface {
  email: string;
  password: string;
  name: string;
  access_until: Date;
  verified: boolean;
  force_new_password: boolean;
  team_id: string;
}

interface CreateResponseInterface extends ErrorInterface {
  user: Student;
}

interface FindAllResponseInterface extends ErrorInterface {
  user: Student[];
}

interface FindOneResponseInterface extends ErrorInterface {
  user: Student;
}

interface UpdateBodyInterface {
  email: string;
  password: string;
  name: string;
  access_until: Date;
  force_new_password: boolean;
}

interface UpdateInterface {
  id: string;
  body: UpdateBodyInterface;
}

interface UpdateResponseInterface extends ErrorInterface {
  user: Student;
}

interface FindTextsResponseInterface extends ErrorInterface {
  texts: Text[];
}

export const studentAPI = {
  create: async (data: CreateInterface): Promise<CreateResponseInterface> => {
    return await fetch(`${API_URL}/student`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { user: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findAll: async (filter?: any): Promise<FindAllResponseInterface> => {
    return await fetch(`${API_URL}/student?${ObjectToQueryString(filter)}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { user: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findOne: async (data: OnlyIDInterface): Promise<FindOneResponseInterface> => {
    return await fetch(`${API_URL}/student/${data.id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { user: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  update: async (data: UpdateInterface): Promise<UpdateResponseInterface> => {
    return await fetch(`${API_URL}/student/${data.id}`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.body),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { user: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findAllTexts: async (
    data: OnlyIDInterface
  ): Promise<FindTextsResponseInterface> => {
    return await fetch(`${API_URL}/student/${data.id}/texts`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { texts: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },
};
