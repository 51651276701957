import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import React from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  YAxis,
} from "recharts";

interface Props {
  data: any[];
  color: string;
  colorId: string;
  max: number;
  min: number;
  stacked?: string;
  stackedColor?: string;
  stackedColorId?: string;
  title: string;
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="p-2 rounded-md shadow-md bg-gray-100 dark:bg-gray-700">
        <p className="text-sm dark:text-white">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const AreaGraph: React.FC<Props> = ({
  data,
  color,
  colorId,
  max,
  min,
  stacked,
  stackedColor,
  stackedColorId,
  title,
}) => {
  return (
    <div className="bg-base-100 rounded-xl shadow-md overflow-hidden pt-8 w-full md:w-[47%] h-[300px] flex flex-col items-center justify-between">
      <div className="flex-1">
        <p className="dark:text-gray-100 text-sm md:text-md lg:text-xl xl:text-2xl">
          {title}
        </p>
      </div>
      {data?.length <= 1 ? (
        <div className="w-full h-[70%] flex flex-col items-center justify-around">
          <QuestionMarkCircleIcon className="text-lg w-[64px] dark:text-gray-100" />
          <p className="dark:text-white">
            Dados insuficientes para gerar um gráfico
          </p>
        </div>
      ) : (
        <ResponsiveContainer height={"70%"}>
          <AreaChart
            data={data
              ?.filter((e) => e.total)
              ?.map(({ total, ...rest }) => ({
                name: `${new Date(rest.created_at).getDate()}/${
                  new Date(rest.created_at).getMonth() + 1
                }`,
                value: total?.toFixed(2) ?? total,
              }))}
            margin={{
              top: 15,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id={`${colorId}`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={color} stopOpacity={1} />
                <stop offset="50%" stopColor={color} stopOpacity={0.5} />
                <stop offset="100%" stopColor={color} stopOpacity={0.2} />
              </linearGradient>
              {stacked && (
                <linearGradient
                  id={`${stackedColorId}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor={stackedColor} stopOpacity={1} />
                  <stop
                    offset="50%"
                    stopColor={stackedColor}
                    stopOpacity={0.5}
                  />
                  <stop
                    offset="100%"
                    stopColor={stackedColor}
                    stopOpacity={0.2}
                  />
                </linearGradient>
              )}
            </defs>
            <XAxis dataKey="name" display={"none"} height={0} />
            {/* <YAxis /> */}
            <Tooltip content={<CustomTooltip />} />
            {stacked && (
              <Area
                max={max}
                min={min}
                type="monotone"
                dataKey={stacked}
                stroke={stackedColor}
                stackId="1"
                fill={`${stackedColor}`}
              />
            )}
            <Area
              max={max}
              min={min}
              type="monotone"
              dataKey="value"
              stroke={color}
              stackId="2"
              fill={`url(#${colorId})`}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default AreaGraph;
