import { API_URL } from "../utils/api";

import { ErrorInterface } from "../utils/interfaces/error.interface";
import { Area, Enem_Competences } from "../utils/interfaces/models";
import { OnlyIDInterface } from "../utils/interfaces/only-id.interface";

interface CreateEnemErrorBody {
  competence: Enem_Competences;
  description: string;
}

interface UpdateEnemErrorBody {
  competence: Enem_Competences;
  description: string;
}

interface CreateAreaInterface {
  area_id: string;
  body: CreateEnemErrorBody;
}

interface CreateEnemErrorResponseInterface extends ErrorInterface {
  enem_error: Area;
}

interface UpdateEnemErrorInterface {
  error_id: string;
  body: UpdateEnemErrorBody;
}

interface UpdateEnemErrroResponseInterface extends ErrorInterface {
  enem_error: Area;
}

export const enemErrorAPI = {
  create: async (
    data: CreateAreaInterface
  ): Promise<CreateEnemErrorResponseInterface> => {
    return await fetch(`${API_URL}/enem-error/${data.area_id}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.body),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { enem_error: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  update: async (
    data: UpdateEnemErrorInterface
  ): Promise<UpdateEnemErrroResponseInterface> => {
    return await fetch(`${API_URL}/enem-error/${data.error_id}`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.body),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { enem_error: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },
};
