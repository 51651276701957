import React, { createRef, useState } from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../../../../utils/context/auth";
import Button from "../../../../../components/Button";
import InputText from "../../../../../components/InputText";
import { themeAPI } from "../../../../../api/theme.api";
import { API_URL } from "../../../../../utils/api";
import InputFile from "../../../../../components/InputFile";
import Table from "../../../../../components/Table";

const ThemesAdminPage: React.FC = () => {
  const { data: themes } = useQuery("themes", themeAPI.findAll, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 5,
    staleTime: 1000 * 5,
  });
  const fileRef = createRef<HTMLInputElement>();
  const pdfRef = createRef<HTMLInputElement>();
  const { notification } = useAuth();
  const [theme, setTheme] = useState("");
  const [loading, setLoading] = useState(false);

  const createTheme = async () => {
    if (fileRef?.current?.files && pdfRef?.current?.files) {
      if (
        fileRef.current.files[0].size > 2000000 ||
        pdfRef.current.files[0].size > 2000000
      ) {
        return notification(
          `${
            fileRef.current.files[0].size > 2000000 ? "Imagem" : "Pdf"
          } muito grande, max 2MB`,
          {
            type: "error",
          }
        );
      }

      setLoading(true);

      const { error, message } = await themeAPI.create({
        image: fileRef?.current?.files[0],
        pdf: pdfRef?.current?.files[0],
        title: theme,
      });

      if (error && message) {
        notification(message, {
          type: "error",
        });
        return;
      }

      notification("Tema criado com sucesso", {
        type: "success",
      });

      setLoading(false);
      setTheme("");
      return;
    }

    return;
  };

  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 px-4 space-y-8 lg:px-8">
          <section aria-labelledby="payment-details-heading">
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-base-100 py-6 px-4 sm:p-6 ">
                  <div>
                    <h2
                      id="payment-details-heading"
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                    >
                      Cadastrar um novo tema
                    </h2>
                  </div>

                  <div className="mt-6 grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="text"
                        label="Nome"
                        value={theme}
                        onChange={(e) => setTheme(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputFile
                        ref={fileRef}
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                        label="Imagem"
                      />
                    </div>
                  </div>

                  <div className="mt-6 grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-2">
                      <InputFile
                        ref={pdfRef}
                        accept="application/pdf"
                        label="Pdf"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
                  <Button
                    type="button"
                    onClick={() => createTheme()}
                    loading={loading}
                    label={"Criar"}
                    color="primary"
                  />
                </div>
              </div>
            </form>
          </section>

          <section aria-labelledby="billing-history-heading">
            <Table
              title="Temas"
              data={
                themes
                  ? themes?.theme?.map((s) => ({
                      id: s.id,
                      image: s.image.url,
                      pdf: s.motivational.url,
                      theme: s.title,
                    }))
                  : []
              }
              fields={[
                {
                  editable: true,
                  field: "theme",
                  name: "Nome",
                  type: "text",
                },
                {
                  editable: false,
                  field: "image",
                  name: "Imagem",
                  type: "url",
                },
                {
                  editable: false,
                  field: "pdf",
                  name: "PDF",
                  type: "url",
                },
              ]}
              onSave={(d) => console.log(d)}
            />
          </section>
        </main>
      </div>
    </>
  );
};

export default ThemesAdminPage;
