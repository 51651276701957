import React, { useEffect, useState } from "react";

import {
  ClockIcon,
  ArchiveIcon,
  InformationCircleIcon,
  CalendarIcon,
} from "@heroicons/react/outline";
import { useAuth } from "../../../../utils/context/auth";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import CardInfo from "../../../../components/CardInfo";
import HeaderDashboard from "../../../../components/HeaderDashboard";
import AreaGraph from "../../../../components/AreaGraph";
import BarGraph from "../../../../components/BarGraph";
import { Role, User } from "../../../../utils/interfaces/models";
import InputText from "../../../../components/InputText";
import { profileAPI } from "../../../../api/profile.api";
import { reportAPI } from "../../../../api/report.api";

const ReportUserPage = () => {
  const { id } = useParams();
  const [textsMonthly, setTextsMonthly] = useState(0);
  const [textsWeekly, setTextsWeekly] = useState(0);
  const [competencesAvg, setCompetencesAvg] = useState<any>(null);
  const [anualPerformance, setAnualPerformance] = useState<any>(null);
  const [user, setUser] = useState<User>();
  const { data: userReport } = useQuery(
    ["user-report", id],
    () => reportAPI.userReport(id!),
    {
      cacheTime: 1000 * 15,
      staleTime: 1000 * 15,
      refetchOnWindowFocus: false,
    }
  );
  const navigate = useNavigate();
  const [cards, setCards] = useState<
    | {
        name: string;
        href: string;
        subtitle: string;
        icon: any;
        color: string;
        value: string;
      }[]
    | null
  >(null);

  useEffect(() => {
    if (userReport) {
      setTextsMonthly(userReport.textsSentMonthly);
      setTextsWeekly(userReport.textsSentWeekly);
      setCompetencesAvg(userReport.avgCompetence);
      setAnualPerformance(userReport.anual);
      setUser(userReport.user);
    }
  }, [userReport]);

  useEffect(() => {
    if (user) {
      const cards = [];

      cards.push({
        name: "Pontos de correção",
        subtitle: "Pontos usados para enviar uma redação",
        color: "text-secondary",
        icon: InformationCircleIcon,
        value: (() => {
          if (user?.profile?.points == 1) {
            return user?.profile?.points + " ponto";
          } else if (user?.profile?.points > 1) {
            return user?.profile?.points + " pontos";
          }

          return "0 ponto";
        })(),
      });

      cards.push({
        name: "Textos enviados este mês pelo aluno",
        subtitle: "Textos enviados este mês pelos seus alunos",
        color: "text-secondary",
        icon: ArchiveIcon,
        value: (() => {
          if (textsMonthly && textsMonthly == 1) {
            return textsMonthly + " texto";
          } else if (textsMonthly && textsMonthly > 1) {
            return textsMonthly + " textos";
          }

          return "0 texto";
        })(),
      });

      cards.push({
        name: "Textos enviados esta semana",
        subtitle: "Textos enviados este mês pelos seus alunos",
        color: "text-accent",
        icon: CalendarIcon,
        value: (() => {
          if (textsWeekly && textsWeekly == 1) {
            return textsWeekly + " texto";
          } else if (textsWeekly && textsWeekly > 1) {
            return textsWeekly + " textos";
          }

          return "0 texto";
        })(),
      });

      setCards(
        Object.assign(
          [],
          [
            {
              name: "Tempo restante na plataforma",
              href: "/app/planos",
              subtitle: "Dias restantes para acessar a plataforma",
              color: "text-primary",
              icon: ClockIcon,
              value: (() => {
                if (!user?.access_until) {
                  return "Nenhum plano ativo ainda";
                }
                const now = new Date();
                const end = new Date(user?.access_until);
                const diff = end.getTime() - now.getTime();
                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                return days + " dias";
              })(),
            },
            ...cards,
          ]
        )
      );
    }
  }, [user, textsWeekly, textsMonthly]);

  return (
    <>
      <HeaderDashboard user={user} />

      <div className="mt-8">
        <div className="flex justify-between flex-wrap items-center max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {cards?.map((card) => (
            <CardInfo
              color={card.color}
              key={card.name}
              name={card.name}
              icon={card.icon}
              value={card.value}
              subtitle={card.subtitle}
              href={card.href}
              onClick={() => navigate(card.href)}
            />
          ))}
        </div>
      </div>
      <div className="mt-8">
        <div className="flex flex-row flex-wrap gap-4 px-4 sm:px-6 lg:px-8 xl:px-10 items-center justify-between">
          {anualPerformance && (
            <AreaGraph
              title={"Notas ao longo do tempo"}
              max={1000}
              min={0}
              colorId="colorUv"
              color={"#8884d8"}
              data={anualPerformance}
            />
          )}
          {competencesAvg && (
            <BarGraph
              title={"Média em cada competência"}
              max={200}
              min={0}
              color={"#ff86b7"}
              data={competencesAvg}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ReportUserPage;
