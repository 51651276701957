import React, { useState } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";
import { useAuth } from "../../../../../utils/context/auth";
import Button from "../../../../../components/Button";
import InputText from "../../../../../components/InputText";
import Select from "../../../../../components/Select";
import Table from "../../../../../components/Table";
import { giftAPI } from "../../../../../api/gift.api";
import { profileAPI } from "../../../../../api/profile.api";
import InputNumber from "../../../../../components/InputNumber";

const GiftsAdminPage: React.FC = () => {
  const { data: gifts } = useQuery("gifts", () => giftAPI.findAll(), {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 5,
    staleTime: 1000 * 5,
  });
  const { data: profiles } = useQuery("profiles", () => profileAPI.findAll(), {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 0,
    staleTime: 1000 * 0,
  });
  const { notification } = useAuth();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [profileId, setProfileId] = useState("");
  const [email, setEmail] = useState(false);
  const [days, setDays] = useState(0);
  const [points, setPoints] = useState(0);
  const [loading, setLoading] = useState(false);

  const createGift = async () => {
    setLoading(true);
    const { error, message } = await giftAPI.create({
      profile_id: profileId,
      description,
      title,
      email,
      days,
      points,
    });

    setLoading(false);
    if (error && message) {
      return notification(message, {
        type: "error",
      });
    }

    return notification("Presente criado com sucesso", {
      type: "success",
    });
  };

  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 px-4 space-y-8 lg:px-8">
          <section aria-labelledby="payment-details-heading">
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-base-100 py-6 px-4 sm:p-6 ">
                  <div>
                    <h2
                      id="payment-details-heading"
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                    >
                      Criar um novo presente
                    </h2>
                  </div>

                  <div className="mt-6 grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="text"
                        label="Titulo"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="text"
                        label="Descrição"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputNumber
                        label="Dias"
                        value={days.toString()}
                        onChange={(e) => setDays(Number(e.target.value))}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputNumber
                        label="Pontos"
                        value={points.toString()}
                        onChange={(e) => setPoints(Number(e.target.value))}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <Select
                        label="Enviar por e-mail"
                        value={email ? "true" : "false"}
                        onChange={(e) => setEmail(e.target.value === "true")}
                      >
                        <option value={"false"}>Não</option>
                        <option value={"true"}>Sim</option>
                      </Select>
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <Select
                        label="Alunos"
                        value={profileId}
                        onChange={(e) => setProfileId(e.target.value)}
                      >
                        <option>Selecione um aluno</option>
                        {profiles &&
                          profiles?.profile?.map((e) => (
                            <option key={e.id} value={e.id}>
                              {e.user.email}
                            </option>
                          ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
                  <Button
                    type="button"
                    onClick={() => createGift()}
                    loading={loading}
                    label={"Criar"}
                    color="primary"
                  />
                </div>
              </div>
            </form>
          </section>

          <section aria-labelledby="billing-history-heading">
            <Table
              title="Presentes"
              data={
                gifts
                  ? gifts?.gift?.map((s) => ({
                      id: s.id,
                      title: s.title,
                    }))
                  : []
              }
              fields={[
                {
                  editable: false,
                  field: "title",
                  name: "Título",
                  type: "text",
                },
              ]}
              onSave={() => {}}
            />
          </section>
        </main>
      </div>
    </>
  );
};

export default GiftsAdminPage;
