export const COMPETENCES = [
  {
    name: "Competencia_1",
    value: "Competência 1",
  },
  {
    name: "Competencia_2",
    value: "Competência 2",
  },
  {
    name: "Competencia_3",
    value: "Competência 3",
  },
  {
    name: "Competencia_4",
    value: "Competência 4",
  },
  {
    name: "Competencia_5",
    value: "Competência 5",
  },
];
