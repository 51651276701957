import { API_URL } from "../utils/api";
import { ErrorInterface } from "../utils/interfaces/error.interface";
import { Corrector } from "../utils/interfaces/models";

interface AnualPerformanceResponseInterface extends ErrorInterface {
  data: Corrector[];
}

export const feeAPI = {
  findAll: async (): Promise<AnualPerformanceResponseInterface> => {
    return await fetch(`${API_URL}/corrector`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },
};
