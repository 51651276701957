import React, { useEffect, useState } from "react";
import DatePicker from "../DatePicker";
import InputNumber from "../InputNumber";
import InputText from "../InputText";
import Select from "../Select";
import moment from "moment";
import {
  CheckIcon,
  ChevronDownIcon,
  PencilIcon,
  XIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import { classNames } from "../../utils/functions/ClassNames";
import Button from "../Button";

interface Field {
  name: string;
  field: string;
  editable: boolean;
  type: "text" | "number" | "select" | "date" | "url";
  selectOptions?: { name: string; value: string }[];
  value?: string;
}

interface Props {
  title: string;
  filterLoading?: boolean;
  find?: () => void;
  filter?: boolean;
  fields: Field[];
  data: any[];
  actions?: { element: (data: any) => void }[];
  onSave: (data: any) => void;
}

const Table: React.FC<Props> = ({
  fields,
  data,
  onSave,
  actions,
  title,
  filterLoading,
  find,
  filter,
  children,
}) => {
  const [filterCollapsed, setFilterCollapsed] = useState<boolean>(true);
  const [editData, setEditData] = useState<any>({});
  const [initialData, setInitialData] = useState<any>({});
  const [inEditMode, setInEditMode] = useState<{
    status: boolean;
    rowKey: string | null;
  }>({
    status: false,
    rowKey: null,
  });

  const onEdit = (id: string, item: any) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setInitialData(item);
    setEditData(item);
  };

  const onSaveIntern = () => {
    const dataToSend = {} as any;

    const isEditable = fields.filter((f) => f.editable);

    isEditable.forEach((f) => {
      if (editData[f.value ?? f.field] !== initialData[f.field]) {
        dataToSend[f.value ?? f.field] = editData[f.value ?? f.field];
      }
    });

    onSave({ id: editData.id, ...dataToSend });

    setInEditMode({
      status: false,
      rowKey: null,
    });
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });

    setEditData(null);
  };

  const onRenderField = ({
    field,
    name,
    editable,
    type,
    selectOptions,
    value,
  }: Field) => {
    switch (type) {
      case "number":
        return (
          <InputNumber
            value={editData[field]}
            onChange={(e) =>
              setEditData({ ...editData, [value ?? field]: e.target.value })
            }
          />
        );
      case "date":
        return (
          <DatePicker
            onChange={(e) =>
              setEditData({
                ...editData,
                // [value ?? field]: moment(e.target.value).toLocaleString(),
                [value ?? field]: new Date(e.target.value),
              })
            }
            value={moment(editData[value ?? field]).format("YYYY-MM-DD")}
          />
        );
      case "select":
        return (
          <Select
            onChange={(e) =>
              setEditData({ ...editData, [value ?? field]: e.target.value })
            }
          >
            {selectOptions?.map(({ name, value }) => {
              return (
                <option key={value} value={value}>
                  {name}
                </option>
              );
            })}
          </Select>
        );
      default:
        return (
          <InputText
            value={editData[field]}
            onChange={(e) =>
              setEditData({ ...editData, [value ?? field]: e.target.value })
            }
          />
        );
    }
  };

  return (
    <div className="bg-white pt-6 shadow rounded-md sm:overflow-hidden dark:bg-gray-900">
      <div className="px-4 sm:px-6 mb-4">
        <h2
          id="billing-history-heading"
          className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
        >
          {title}
        </h2>
      </div>
      <div className="px-2 flex flex-col">
        {filter && (
          <div
            className={classNames(
              "collapse border-t-[1px] border-t-slate-400",
              filterCollapsed ? "collapse-open" : ""
            )}
          >
            <div
              className="collapse-title text-xl font-medium flex flex-row"
              onClick={() => setFilterCollapsed(!filterCollapsed)}
            >
              Filtros{" "}
              {filterCollapsed ? (
                <ChevronUpIcon width={24} />
              ) : (
                <ChevronDownIcon width={24} />
              )}
            </div>
            <div className="collapse-content">
              {children}
              <Button
                label="Buscar"
                loading={filterLoading}
                disabled={filterLoading}
                onClick={() => find && find()}
                color={"primary"}
              />
            </div>
          </div>
        )}
      </div>
      <div className="overflow-x-auto">
        <table className="table table-zebra w-full">
          <thead>
            <tr>
              {fields.map((field) => (
                <th key={field.name}>{field.name}</th>
              ))}
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                {fields.map(
                  ({ name, editable, field, selectOptions, type, value }) =>
                    editable ? (
                      <td key={field}>
                        {inEditMode.status && inEditMode.rowKey === item.id
                          ? onRenderField({
                              field,
                              name,
                              editable,
                              type,
                              selectOptions,
                              // ...(value && { value: item[value] }),
                              ...(value && { value }),
                            })
                          : type === "date"
                          ? moment(item[field]).format("DD/MM/YYYY")
                          : item[field]}
                      </td>
                    ) : (
                      <td key={field}>
                        {type === "date" ? (
                          moment(item[field]).format("DD/MM/YYYY")
                        ) : type === "url" ? (
                          <a
                            href={item[field]}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500"
                          >
                            Link
                          </a>
                        ) : (
                          item[field]
                        )}
                      </td>
                    )
                )}
                <td className="flex flex-row gap-2 items-center overflow-hidden h-[56px]">
                  {fields.find((f) => f.editable === true) &&
                    (inEditMode.status && inEditMode.rowKey === item.id ? (
                      <React.Fragment>
                        <button
                          className="text-green-500 hover:text-green-700"
                          onClick={() => onSaveIntern()}
                        >
                          <CheckIcon width={18} height={18} color="#00ff90" />
                        </button>

                        <button
                          className="text-red-500 hover:text-red-700"
                          style={{ marginLeft: 8 }}
                          onClick={() => onCancel()}
                        >
                          <XIcon width={18} height={18} color="#ff2000" />
                        </button>
                      </React.Fragment>
                    ) : (
                      <button
                        className="text-blue-500 hover:text-blue-600"
                        onClick={() => onEdit(item.id, item)}
                      >
                        <PencilIcon color="#0099ff" width={18} height={18} />
                      </button>
                    ))}
                  {(!inEditMode.status || !(inEditMode.rowKey === item.id)) &&
                    actions?.map(({ element }: any) => element(item))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
