import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import Button from "../../../../../components/Button";
import { BodyFinishText, textAPI } from "../../../../../api/text.api";
import { useAuth } from "../../../../../utils/context/auth";
import RegionSelect from "react-region-select";
import Region from "../../../../../components/Region";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../../components/Modal";
import TextArea from "../../../../../components/TextArea";
import InputNumber from "../../../../../components/InputNumber";
import Lottie from "react-lottie";
// @ts-ignore: Unreachable code error
import animationData from "../../../../../utils/lotties/empty";
import { COMPETENCES } from "../../../../../utils/competences";
import {
  Area,
  EnemAreaError,
  Enem_Competences,
  Text_Status,
} from "../../../../../utils/interfaces/models";
import { areaAPI } from "../../../../../api/area.api";
import { enemErrorAPI } from "../../../../../api/enem-error.api";
import Loading from "../../../../../components/Loading";

export interface RegionInterface {
  x: Number; // x, y, width, height are percentages, off the upper left corner
  y: Number; // (0, 0, 50, 50) is a rectangle in the north western corner of the image
  width: Number;
  height: Number;
  data: any; // any additional data you may need
  isChanging?: Boolean;
  new?: Boolean;
}

enum CheckEnum {
  Revisado = "Revisado",
  Revisando = "Revisando",
}

const CorrectPage = () => {
  const { notification } = useAuth();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState<{ title: string; type: number } | null>(
    null
  );
  const [check, setCheck] = useState<CheckEnum | null>(null);
  const navigate = useNavigate();
  const {
    data: text,
    refetch,
    isLoading,
  } = useQuery("text-correct", textAPI.startCorrect, {
    refetchOnWindowFocus: false,
  });
  const [regions, setRegions] = useState<Area[]>([]);
  const [observation, setObservation] = useState<string>("");
  const [competence1, setCompetence1] = useState<string>("");
  const [competence2, setCompetence2] = useState<string>("");
  const [competence3, setCompetence3] = useState<string>("");
  const [competence4, setCompetence4] = useState<string>("");
  const [competence5, setCompetence5] = useState<string>("");

  const updateText = async (data: BodyFinishText) => {
    if (text?.text) {
      setLoading(true);
      const { message, error } = await textAPI.finishCorrection({
        id: text.text.id,
        body: data,
      });
      setLoading(false);
      if (error && message) {
        return notification(
          message ?? "Erro ao finzalizar texto. Tente novamente",
          {
            type: "warning",
          }
        );
      }

      notification("Correção enviada!", {
        type: "success",
        autoClose: 1000,
      });
      return navigate("/app");
    }
    return;
  };

  const removeRegion = async (index: number) => {
    const newRegions = regions.filter(
      (_: RegionInterface, i: number) => i !== index
    );
    newRegions.forEach((region: RegionInterface, i: number) => {
      region.data.index = i;
    });
    setRegions(Object.assign([], [...newRegions]));

    const { error, message } = await areaAPI.delete({ id: regions[index].id });
    setCheck(null);

    if (error && message) {
      return notification(message, {
        type: "warning",
      });
    }

    return notification("Area removida com sucesso!", {
      type: "success",
      autoClose: 1000,
    });
  };

  const setRegionData = async (index: number, data: EnemAreaError) => {
    const newRegions = [...regions];
    newRegions[index].data = data;

    const color =
      data.competence === Enem_Competences.Competencia_1
        ? "#ff0000"
        : data.competence === Enem_Competences.Competencia_2
        ? "#0000ff"
        : data.competence === Enem_Competences.Competencia_3
        ? "#ffff00"
        : data.competence === Enem_Competences.Competencia_4
        ? "#ff00ff"
        : "#00ff00";

    newRegions[index].data.color = color;
    setRegions(Object.assign([], [...newRegions]));

    const { error, message } = await enemErrorAPI.update({
      error_id: data.id,
      body: {
        competence: data.competence ?? null,
        description: data.description ?? null,
      },
    });
    setCheck(null);

    if (error && message) {
      return notification(message, {
        type: "warning",
      });
    }

    return notification("Atualizado com sucesso!", {
      type: "success",
      autoClose: 1000,
    });
  };

  useEffect(() => {
    if (text?.error && text?.message) {
      notification(text?.message, {
        type: "warning",
      });
    }
    if (text?.text) {
      setRegions(text?.text?.areas ?? []);
    }
  }, [text?.text]);

  if (isLoading) {
    return <Loading big padding />;
  }

  if (text?.error || !text?.text) {
    return (
      <div className="w-full  flex items-center justify-around pt-8">
        <div className="w-full md:w-[70%] lg:w-[55%] flex-col flex items-center">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={"100%"}
            width={"100%"}
          />
          <p className="text-gray-800 dark:text-gray-100 text-lg mt-6">
            Não possuem textos para serem corrigidos no momento.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex flex-col p-8 bg-base-100 shadow-lg">
        <div className="w-full mb-4 border-b-[1px] border-t-gray-400 dark:border-gray-700 border-t-0">
          <p className="text-xl dark:text-white mb-4">
            {text?.text?.theme?.title}
          </p>
        </div>
        <div className="w-full">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Aqui você pode corrigir o texto feito por{" "}
            <b>{text?.text?.student?.profile?.name}</b>. Para isso, basta
            selecionar a região do texto e adicionar notas mostrando os erros
            cometidos.
          </p>
        </div>
        <div className="w-full mt-4 flex flex-row items-center justify-start space-x-8">
          <a
            href={text?.text?.theme?.motivational?.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row items-center justify-start text-blue-600 w-auto cursor-pointer text-sm lg:text-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-4 text-blue-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
              />
            </svg>
            <p className="truncate">PDF motivacional</p>
          </a>
          <div className="flex flex-1 space-x-2 flex-row items-center justify-end">
            {check === null ? (
              <Button
                responsive
                label="Revisar"
                type="button"
                color="success"
                onClick={() => {
                  setCheck(CheckEnum.Revisando);
                  refetch();
                }}
              />
            ) : check === CheckEnum.Revisando ? (
              <Button
                responsive
                label="Finalizar revisão"
                type="button"
                color="primary"
                onClick={() => setCheck(CheckEnum.Revisado)}
              />
            ) : (
              <></>
            )}
            {text &&
              text?.text?.status === "Corrigindo" &&
              check === CheckEnum.Revisado && (
                <>
                  <Button
                    responsive
                    label="Finalizar correção"
                    type="button"
                    color="success"
                    onClick={() => setModal({ type: 1, title: "Finalizar" })}
                  />
                  <Button
                    responsive
                    label="Anular"
                    type="button"
                    color="danger"
                    onClick={() => setModal({ type: 2, title: "Anular" })}
                  />
                  <Button
                    responsive
                    label="Devolver"
                    type="button"
                    color="light"
                    onClick={() => setModal({ type: 3, title: "Devolver" })}
                  />
                </>
              )}
          </div>
        </div>
      </div>
      <div className="m-8 p-6 rounded-md shadow-md dark:shadow-gray-700 bg-base-100 flex flex-col">
        <div className="w-full flex flex-row items-center justify-center border-t-[0px] mt-0 border-t-gray-400 dark:border-t-gray-700 pt-4">
          {text?.text?.image && (
            <RegionSelect
              regions={
                regions.length > 0
                  ? regions?.map((r: RegionInterface, i: number) => {
                      !r.data && (r.data = {});
                      r.data.index = i;
                      return r;
                    })
                  : []
              }
              onChange={async (regions: RegionInterface[]) => {
                if (
                  regions.length !== 0 &&
                  regions[regions.length - 1].isChanging === false &&
                  regions[regions.length - 1].new === false
                ) {
                  const { area, error, message } = await areaAPI.create({
                    text_id: text?.text?.id,
                    body: {
                      height: regions[regions.length - 1].height,
                      width: regions[regions.length - 1].width,
                      x: regions[regions.length - 1].x,
                      y: regions[regions.length - 1].y,
                    },
                  });
                  setCheck(null);

                  if (error && message) {
                    notification(message, {
                      type: "warning",
                    });

                    const cloneRegions = [...regions];
                    cloneRegions.pop();

                    return setRegions(Object.assign([], [...cloneRegions]));
                  }

                  const cloneRegions = [...regions];
                  cloneRegions.pop();
                  cloneRegions.push({
                    ...area,
                    new: false,
                    isChanging: false,
                    data: {
                      ...area.data,
                      index: regions.length,
                    },
                  });

                  notification("Área criada com sucesso!", {
                    type: "success",
                    autoClose: 1000,
                  });

                  return setRegions(Object.assign([], [...cloneRegions]));
                } else {
                  return setRegions(Object.assign([], [...regions]));
                }
              }}
              className="h-[80%] w-[80%] rounded-md overflow-hidden"
              regionRenderer={({ data }: { data: any; isChanging: any }) => {
                return (
                  <Region
                    setCompetence={(newData) =>
                      setRegionData(data.index, { ...data, ...newData })
                    }
                    competences={COMPETENCES}
                    data={data ?? {}}
                    removeRegion={() => removeRegion(data.index)}
                  />
                );
              }}
            >
              <img
                src={text?.text?.image?.url}
                alt="Redação"
                className="h-[100%] w-[100%] rounded-md"
              />
            </RegionSelect>
          )}
        </div>
      </div>
      <Modal isOpen={!!modal}>
        <ModalHeader
          default
          title={modal?.title}
          onClose={() => setModal(null)}
        />
        <ModalBody>
          <form action="#" method="POST">
            <div className="bg-transparent  py-0 px-2 space-y-2 sm:p-0">
              <div>
                {modal?.type === 1 && (
                  <div className="w-full grid grid-cols-2 gap-2">
                    <InputNumber
                      max={"200"}
                      min={"100"}
                      label="Competencia 1"
                      onChange={(e) => setCompetence1(e.target.value)}
                      value={competence1}
                    />
                    <InputNumber
                      max={"200"}
                      min={"100"}
                      label="Competencia 2"
                      onChange={(e) => setCompetence2(e.target.value)}
                      value={competence2}
                    />
                    <InputNumber
                      max={"200"}
                      min={"100"}
                      label="Competencia 3"
                      onChange={(e) => setCompetence3(e.target.value)}
                      value={competence3}
                    />
                    <InputNumber
                      max={"200"}
                      min={"100"}
                      label="Competencia 4"
                      onChange={(e) => setCompetence4(e.target.value)}
                      value={competence4}
                    />
                    <InputNumber
                      max={"200"}
                      min={"100"}
                      label="Competencia 5"
                      onChange={(e) => setCompetence5(e.target.value)}
                      value={competence5}
                    />
                  </div>
                )}
                <TextArea
                  maxLength={1000}
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                  label={
                    modal?.type !== 1
                      ? "Descreva o motivo"
                      : "Observações adicionais"
                  }
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loading}
            color="primary"
            loading={loading}
            label={"Enviar"}
            onClick={() => {
              if (modal?.type === 1) {
                if (
                  competence1 === "" ||
                  competence2 === "" ||
                  competence3 === "" ||
                  competence4 === "" ||
                  competence5 === ""
                ) {
                  notification("Preencha todas as competências", {
                    type: "warning",
                  });
                  return;
                }
                if (Number(competence1) < 0 || Number(competence1) > 200) {
                  notification("Competência 1 deve estar entre 0 e 200", {
                    type: "warning",
                  });
                  return;
                }

                if (Number(competence2) < 0 || Number(competence2) > 200) {
                  notification("Competência 2 deve estar entre 0 e 200", {
                    type: "warning",
                  });
                  return;
                }

                if (Number(competence3) < 0 || Number(competence3) > 200) {
                  notification("Competência 3 deve estar entre 0 e 200", {
                    type: "warning",
                  });
                  return;
                }

                if (Number(competence4) < 0 || Number(competence4) > 200) {
                  notification("Competência 4 deve estar entre 0 e 200", {
                    type: "warning",
                  });
                  return;
                }

                if (Number(competence5) < 0 || Number(competence5) > 200) {
                  notification("Competência 5 deve estar entre 0 e 200", {
                    type: "warning",
                  });
                  return;
                }
              }
              updateText({
                ...(modal?.type === 1 && {
                  comp1: Number(competence1),
                  comp2: Number(competence2),
                  comp3: Number(competence3),
                  comp4: Number(competence4),
                  comp5: Number(competence5),
                }),
                status:
                  modal?.type === 1
                    ? Text_Status.Corrigido
                    : modal?.type === 2
                    ? Text_Status.Anulado
                    : Text_Status.Devolvido,
                corrector_observation: observation,
              });
            }}
          />

          <Button
            disabled={loading}
            color="light"
            label={"Cancelar"}
            onClick={() => setModal(null)}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CorrectPage;
