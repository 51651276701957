import { API_URL } from "../utils/api";
import { ErrorInterface } from "../utils/interfaces/error.interface";
import { School } from "../utils/interfaces/models";
import { OnlyIDInterface } from "../utils/interfaces/only-id.interface";

interface CreateInterface {
  name: string;
  subdomain: string;
  access_until: Date;
  limit_users: number;
  points_per_month: number;
}

interface UpdateBodyInterface {
  name?: string;
  subdomain?: string;
  access_until?: Date;
  limit_users?: number;
  points_per_month?: number;
}

interface UpdateInterface {
  id: string;
  body: UpdateBodyInterface;
}

interface CreateResponseInterface extends ErrorInterface {
  school: School;
}

interface FindAllResponseInterface extends ErrorInterface {
  school: School[];
}

interface FindOneResponseInterface extends ErrorInterface {
  school: School;
}

interface UpdateResponseInterface extends ErrorInterface {
  school: School;
}

export const schoolAPI = {
  create: async (data: CreateInterface): Promise<CreateResponseInterface> => {
    return await fetch(`${API_URL}/school`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { school: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findAll: async (): Promise<FindAllResponseInterface> => {
    return await fetch(`${API_URL}/school`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { school: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findOne: async (data: OnlyIDInterface): Promise<FindOneResponseInterface> => {
    return await fetch(`${API_URL}/school/${data.id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { school: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  update: async (data: UpdateInterface): Promise<UpdateResponseInterface> => {
    return await fetch(`${API_URL}/school/${data.id}`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.body),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { school: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },
};
