import React, { useState } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";
import { useAuth } from "../../../../../utils/context/auth";
import Button from "../../../../../components/Button";
import InputText from "../../../../../components/InputText";
import { schoolAPI } from "../../../../../api/school.api";
import InputNumber from "../../../../../components/InputNumber";
import DatePicker from "../../../../../components/DatePicker";
import Table from "../../../../../components/Table";

const SchoolsAdminPage: React.FC = () => {
  const { data: schools, refetch } = useQuery("schools", schoolAPI.findAll, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 0,
    staleTime: 1000 * 0,
  });
  const { notification } = useAuth();
  const [points_per_month, setPoints_per_month] = useState("");
  const [limit_users, setLimit_users] = useState("");
  const [access_until, setAccess_until] = useState("");
  const [name, setName] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [loading, setLoading] = useState(false);

  const createSchool = async () => {
    setLoading(true);
    const { error } = await schoolAPI.create({
      access_until: new Date(access_until),
      limit_users: Number(limit_users),
      name,
      points_per_month: Number(points_per_month),
      subdomain,
    });

    setLoading(false);
    if (error) {
      return notification("Erro ao criar escola!", {
        type: "error",
      });
    }

    refetch();

    setAccess_until("");
    setName("");
    setLimit_users("");
    setSubdomain("");
    setPoints_per_month("");

    return notification("Escola criada com sucesso!", {
      type: "success",
    });
  };

  const edit = async (obj: any) => {
    const { id, ...body } = obj;
    const { message, error } = await schoolAPI.update({
      id,
      body: {
        ...(body.access_until && { access_until: new Date(body.access_until) }),
        ...(body.limit_users && { limit_users: Number(body.limit_users) }),
        ...(body.name && { name: body.name }),
        ...(body.points_per_month && {
          points_per_month: Number(body.points_per_month),
        }),
      },
    });

    if (error && message) {
      return notification(message, {
        type: "error",
      });
    }

    refetch();
    return notification("Escola atualizada com sucesso!", {
      type: "success",
    });
  };

  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 px-4 space-y-8 lg:px-8">
          <section>
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="py-6 px-4 sm:p-6 bg-base-100">
                  <div>
                    <h2
                      id="payment-details-heading"
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                    >
                      Cadastrar uma nova escola
                    </h2>
                  </div>

                  <div className="mt-6 grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="text"
                        label="Nome da escola"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputNumber
                        label="Pontos por mês"
                        onChange={(e) => setPoints_per_month(e.target.value)}
                        value={points_per_month}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputNumber
                        label="Subdomínio"
                        onChange={(e) => setSubdomain(e.target.value)}
                        value={subdomain}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputNumber
                        label="Usuários máximo"
                        onChange={(e) => setLimit_users(e.target.value)}
                        value={limit_users}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <DatePicker
                        label="Acesso até"
                        onChange={(e) =>
                          setAccess_until(
                            moment(e.target.value).toLocaleString()
                          )
                        }
                        value={moment(access_until).format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
                  <Button
                    type="button"
                    onClick={() => createSchool()}
                    loading={loading}
                    label={"Criar"}
                    color="primary"
                  />
                </div>
              </div>
            </form>
          </section>

          <section aria-labelledby="billing-history-heading">
            <Table
              title="Escolas"
              data={
                schools
                  ? schools?.school?.map((s) => ({
                      id: s.id,
                      name: s.name,
                      limit_users: s.limit_users,
                      points_per_month: s.points_per_month,
                      access_until: s.access_until,
                    }))
                  : []
              }
              fields={[
                {
                  editable: false,
                  field: "name",
                  name: "Nome",
                  type: "text",
                },
                {
                  editable: true,
                  field: "limit_users",
                  name: "Usuários Max.",
                  type: "number",
                },
                {
                  editable: true,
                  field: "points_per_month",
                  name: "Pontos/mês",
                  type: "number",
                },
                {
                  editable: true,
                  field: "access_until",
                  name: "Acesso até",
                  type: "date",
                },
              ]}
              onSave={(d) => edit(d)}
            />
          </section>
        </main>
      </div>
    </>
  );
};

export default SchoolsAdminPage;
