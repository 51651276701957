import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import CardImage from "../../../../components/VideoCard";
import { themeAPI } from "../../../../api/theme.api";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { textAPI } from "../../../../api/text.api";
import { useAuth } from "../../../../utils/context/auth";

const DefaultGridCover: React.FC = ({ children }) => {
  return (
    <div className="mt-8">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <ul
          role="list"
          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {children}
        </ul>
      </div>
    </div>
  );
};

const ThemesPage = () => {
  const { notification, user, setUser } = useAuth();
  const [modal, setModal] = useState<{
    title: string;
    id: string;
  } | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { data: themes, status } = useQuery("themes", themeAPI.findAll, {
    cacheTime: 1000 * 60,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
  });

  const startText = async () => {
    if (modal?.id) {
      setLoading(true);

      const { text, error, message } = await textAPI.create({
        theme_id: modal.id,
      });

      setLoading(false);

      if (error && message) {
        return notification(message, { type: "error" });
      }

      if (user) {
        setUser({
          ...user,
          profile: { ...user?.profile, points: user?.profile?.points - 1 },
        });
      }

      return navigate(`/app/escrever/${text?.id}`);
    }
  };

  if (status === "loading") {
    return (
      <DefaultGridCover>
        {[1, 1, 1, 1, 1, 1].map((video, i) => (
          <CardImage key={i} loading />
        ))}
      </DefaultGridCover>
    );
  }

  if (!themes?.theme) {
    return (
      <div className="text-center mt-16">
        <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">
          Nenhum tema para mostrar
        </h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
          Compre pontos de correção para ter acesso aos temas disponíveis!
        </p>
      </div>
    );
  }

  return (
    <DefaultGridCover>
      {themes &&
        themes?.theme?.map((theme) => (
          <CardImage
            key={theme.id}
            onClick={() =>
              setModal({
                title: theme.title,
                id: theme.id,
              })
            }
            image={theme?.image}
            title={theme?.title}
            id={theme.id}
            buttonText="Iniciar texto"
          />
        ))}
      <Modal isOpen={!!modal}>
        <ModalHeader
          default
          title="Iniciar redação?"
          onClose={() => setModal(null)}
        />
        <ModalBody>
          <form action="#" method="POST">
            <div className="bg-transparent  py-0 px-2 space-y-2 sm:p-0">
              <div>
                <p className="mt-0 text-sm text-gray-500 dark:text-white">
                  Deseja iniciar a redação sobre o tema: <b>{modal?.title}</b>?
                </p>
                <p className="text-xs text-gray-500 dark:text-white mt-2">
                  Obs: Você não gasta pontos por iniciar uma redação. Pontos são
                  descontados apenas quando uma redação é enviada.
                </p>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loading}
            color="primary"
            loading={loading}
            label={"Iniciar redação"}
            onClick={() => startText()}
          />

          <Button
            disabled={loading}
            color="light"
            label={"Cancelar"}
            onClick={() => setModal(null)}
          />
        </ModalFooter>
      </Modal>
    </DefaultGridCover>
  );
};

export default ThemesPage;
