import React, { useState } from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../../../../utils/context/auth";
import Button from "../../../../../components/Button";
import InputText from "../../../../../components/InputText";
import Select from "../../../../../components/Select";
import { schoolAPI } from "../../../../../api/school.api";
import { teamAPI } from "../../../../../api/team.api";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../../../components/Table";
import { ChartBarIcon } from "@heroicons/react/solid";
import { teacherAPI } from "../../../../../api/teacher.api";

const ClassesCoordinatorPage: React.FC = () => {
  const { data: teachers } = useQuery(
    "school-teachers",
    () => teacherAPI.findAll(),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 5,
      staleTime: 1000 * 5,
    }
  );
  const { data: classes, refetch } = useQuery("classes", teamAPI.findAll, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 5,
    staleTime: 1000 * 5,
  });
  const { notification } = useAuth();
  const [teacher, setTeacher] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const update = async (data: any) => {
    const { id, ...body } = data;

    const { error, message } = await teamAPI.update({ id, body });

    if (error && message) {
      return notification(message, {
        type: "error",
      });
    }

    refetch();
    return notification("Usuário atualizado com sucesso", {
      type: "success",
    });
  };

  const createSchool = async () => {
    if (name.trim() === "") {
      return notification("Defina um nome", {
        type: "warning",
      });
    }

    setLoading(true);
    const { error, message } = await teamAPI.create({
      name,
      teacher,
    });

    setLoading(false);
    if (error && message) {
      return notification(message, {
        type: "error",
      });
    }

    refetch();

    setTeacher("");
    setName("");

    return notification("Turma cadastrada com sucesso", {
      type: "success",
    });
  };

  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 px-4 space-y-8 lg:px-8">
          <section aria-labelledby="payment-details-heading">
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-base-100 py-6 px-4 sm:p-6 ">
                  <div>
                    <h2
                      id="payment-details-heading"
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                    >
                      Cadastrar uma nova turma
                    </h2>
                  </div>

                  <div className="mt-6 grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="text"
                        label="Nome da turma"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <Select
                        label="Professor principal"
                        value={teacher}
                        onChange={(e) => setTeacher(e.target.value)}
                      >
                        <option>Selecione um professor</option>
                        {teachers &&
                          teachers?.user?.map((e) => (
                            <option value={e?.id}>{e.profile?.name}</option>
                          ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
                  <Button
                    type="button"
                    onClick={() => createSchool()}
                    loading={loading}
                    label={"Criar"}
                    color="primary"
                  />
                </div>
              </div>
            </form>
          </section>

          <section aria-labelledby="billing-history-heading">
            <Table
              title="Turmas"
              data={
                classes?.team
                  ? classes?.team?.map((s) => ({
                      id: s.id,
                      name: s.name,
                      teacher: s.teacher?.profile?.name,
                    }))
                  : []
              }
              fields={[
                {
                  editable: true,
                  field: "name",
                  name: "Nome",
                  type: "text",
                },
                {
                  editable: true,
                  field: "teacher",
                  name: "Professor",
                  type: "select",
                  value: "teacher_id",
                  ...(teachers?.user && {
                    selectOptions: teachers?.user?.map((e) => ({
                      value: e.id,
                      name: e.profile?.name,
                    })),
                  }),
                },
              ]}
              actions={[
                {
                  element: ({ id }: any) => (
                    <Link
                      className="text-blue-500 hover:text-blue-600"
                      // onClick={() => navigate("/app/relatorio/turma/" + id)}
                      to={"/app/relatorio/turma/" + id}
                    >
                      <ChartBarIcon color="#0099ff" width={18} height={18} />
                    </Link>
                  ),
                },
              ]}
              onSave={(d) => update(d)}
            />
          </section>
        </main>
      </div>
    </>
  );
};

export default ClassesCoordinatorPage;
