import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import React from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  YAxis,
  Bar,
  Legend,
  CartesianGrid,
  BarChart,
} from "recharts";

interface Props {
  data: any;
  color: string;
  max: number;
  min: number;
  title: string;
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="p-2 rounded-md shadow-md bg-gray-100 dark:bg-gray-700">
        <p className="text-sm dark:text-white">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const BarGraph: React.FC<Props> = ({ data, color, max, min, title }) => {
  return (
    <div className="bg-base-100 rounded-xl shadow-md overflow-hidden pt-8 w-full md:w-[47%] h-[300px] flex flex-col items-center justify-between">
      <div className="flex-1">
        <p className="dark:text-gray-100 text-sm md:text-md lg:text-xl xl:text-2xl">
          {title}
        </p>
      </div>
      {!data.comp1 ? (
        <div className="w-full h-[70%] flex flex-col items-center justify-around">
          <QuestionMarkCircleIcon className="text-lg w-[64px] dark:text-gray-100" />
          <p className="dark:text-white">
            Dados insuficientes para gerar um gráfico
          </p>
        </div>
      ) : (
        <ResponsiveContainer height={"70%"}>
          <BarChart
            data={Object.keys(data).map((key: string) => {
              return {
                name:
                  key === "comp1"
                    ? "Competência 1"
                    : key === "comp2"
                    ? "Competência 2"
                    : key === "comp3"
                    ? "Competência 3"
                    : key === "comp4"
                    ? "Competência 4"
                    : "Competência 5",
                Nota: data[key].toFixed(2),
              };
            })}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <XAxis dataKey="name" display={"none"} height={0} />
            {/* <YAxis /> */}
            <Tooltip cursor={false} labelClassName="text-neutral" />

            <Bar fill={`${color}`} max={max} min={min} dataKey="Nota" />
            {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default BarGraph;
