import React from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../../../../utils/context/auth";
import { teamAPI } from "../../../../../api/team.api";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../../../components/Table";
import { ChartBarIcon } from "@heroicons/react/solid";

const ClassesTeacherPage: React.FC = () => {
  const { data: classes } = useQuery("teacher-classes", teamAPI.findAll, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 5,
    staleTime: 1000 * 5,
  });
  const navigate = useNavigate();

  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 px-4 space-y-8 lg:px-8">
          <section aria-labelledby="billing-history-heading">
            <Table
              title="Turmas"
              data={
                classes?.team
                  ? classes?.team?.map((s) => ({
                      id: s.id,
                      name: s.name,

                      teacher: s.teacher?.profile?.name,
                    }))
                  : []
              }
              fields={[
                {
                  editable: false,
                  field: "name",
                  name: "Nome",
                  type: "text",
                },
              ]}
              actions={[
                {
                  element: ({ id }: any) => (
                    <Link
                      className="text-blue-500 hover:text-blue-600"
                      // onClick={() => navigate("/app/relatorio/turma/" + id)}
                      to={"/app/relatorio/turma/" + id}
                    >
                      <ChartBarIcon color="#0099ff" width={18} height={18} />
                    </Link>
                  ),
                },
              ]}
              onSave={(d) => console.log(d)}
            />
          </section>
        </main>
      </div>
    </>
  );
};

export default ClassesTeacherPage;
