import React, { useEffect, useState } from "react";

import { ArchiveIcon, CalendarIcon } from "@heroicons/react/outline";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import CardInfo from "../../../../components/CardInfo";
import AreaGraph from "../../../../components/AreaGraph";
import BarGraph from "../../../../components/BarGraph";
import { Team } from "../../../../utils/interfaces/models";

import { reportAPI } from "../../../../api/report.api";

const ReportTeamPage = () => {
  const { id } = useParams();
  const [textsMonthly, setTextsMonthly] = useState(0);
  const [textsWeekly, setTextsWeekly] = useState(0);
  const [competencesAvg, setCompetencesAvg] = useState<any>(null);
  const [anualPerformance, setAnualPerformance] = useState<any>(null);
  const [team, setTeam] = useState<Team>();
  const { data: userReport } = useQuery(
    ["team-report", id],
    () => reportAPI.teamReport(id!),
    {
      cacheTime: 1000 * 15,
      staleTime: 1000 * 15,
      refetchOnWindowFocus: false,
    }
  );
  const navigate = useNavigate();
  const [cards, setCards] = useState<
    | {
        name: string;
        href: string;
        subtitle: string;
        icon: any;
        color: string;
        value: string;
      }[]
    | null
  >(null);

  useEffect(() => {
    if (userReport) {
      setTextsMonthly(userReport.textsSentMonthly);
      setTextsWeekly(userReport.textsSentWeekly);
      setCompetencesAvg(userReport.avgCompetence);
      setAnualPerformance(userReport.anual);
      setTeam(userReport.team);
    }
  }, [userReport]);

  useEffect(() => {
    if (team) {
      const cards = [];

      cards.push({
        name: "Textos enviados este mês",
        subtitle: "Textos enviados este mês pelos seus alunos",
        color: "text-secondary",
        icon: ArchiveIcon,
        value: (() => {
          if (textsMonthly && textsMonthly >= 0) {
            return textsMonthly + " textos";
          }

          return "0 textos";
        })(),
      });

      cards.push({
        name: "Textos enviados esta semana",
        subtitle: "Textos enviados este mês pelos seus alunos",
        color: "text-accent",
        icon: CalendarIcon,
        value: (() => {
          if (textsWeekly && textsWeekly >= 0) {
            return textsWeekly + " textos";
          }

          return "0 textos";
        })(),
      });

      setCards(Object.assign([], [...cards]));
    }
  }, [team, textsWeekly, textsMonthly]);

  return (
    <>
      <div className="shadow bg-base-100">
        <div className="px-6 sm:px-4 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200 dark:border-gray-800">
            <div className="flex-1 min-w-0">
              <div className="flex items-center">
                <div className="flex items-center">
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate dark:text-gray-200">
                    Relatório da turma - {team?.name}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-between flex-wrap items-center max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {cards?.map((card) => (
            <CardInfo
              color={card.color}
              key={card.name}
              name={card.name}
              icon={card.icon}
              value={card.value}
              subtitle={card.subtitle}
              href={card.href}
              onClick={() => navigate(card.href)}
            />
          ))}
        </div>
      </div>
      <div className="mt-8">
        <div className="flex flex-row flex-wrap gap-4 px-4 sm:px-6 lg:px-8 xl:px-10 items-center justify-between">
          {anualPerformance && (
            <AreaGraph
              title={"Notas ao longo do tempo"}
              max={1000}
              min={0}
              colorId="colorUv"
              color={"#8884d8"}
              data={anualPerformance}
            />
          )}
          {competencesAvg && (
            <BarGraph
              title={"Média em cada competência"}
              max={200}
              min={0}
              color={"#ff86b7"}
              data={competencesAvg}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ReportTeamPage;
