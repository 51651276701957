import React, { createRef, useState } from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../../../../utils/context/auth";
import Button from "../../../../../components/Button";
import InputText from "../../../../../components/InputText";
import { API_URL } from "../../../../../utils/api";
import InputFile from "../../../../../components/InputFile";
import Table from "../../../../../components/Table";
import { videoAPI } from "../../../../../api/video.api";

const VideosAdminPage: React.FC = () => {
  const { data: videos, refetch } = useQuery("videos", videoAPI.findAll, {
    refetchOnWindowFocus: false,
    // cacheTime: 1000 * 5,
    // staleTime: 1000 * 5,
  });
  // const fileRef = createRef<HTMLInputElement>();
  // const pdfRef = createRef<HTMLInputElement>();
  const { notification } = useAuth();
  // const [theme, setTheme] = useState("");
  // const [loading, setLoading] = useState(false);

  // const createTheme = () => {
  //   if (fileRef?.current?.files && pdfRef?.current?.files) {
  //     const formData = new FormData();

  //     formData.append("image", fileRef?.current?.files[0]);
  //     formData.append("pdf", pdfRef?.current?.files[0]);
  //     formData.append("theme", theme);

  //     setLoading(true);

  //     fetch(API_URL + "/themes", {
  //       method: "POST",
  //       credentials: "include",
  //       body: formData,
  //     })
  //       .then(async (res) => {
  //         const { success, error } = await res.json();
  //         if (error) {
  //           return notification(error ?? "Erro ao criar o tema", {
  //             type: "warning",
  //           });
  //         }

  //         setTheme("");

  //         return notification(success ?? "Tema criado com sucesso!", {
  //           type: "success",
  //         });
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         notification("Erro ao criar tema", { type: "error" });
  //       });
  //   }
  // };

  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 px-4 space-y-8 lg:px-8">
          <section aria-labelledby="payment-details-heading">
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-base-100 py-6 px-4 sm:p-6 ">
                  <div>
                    <h2
                      id="payment-details-heading"
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                    >
                      Cadastrar um novo vídeo
                    </h2>
                  </div>

                  {/* <div className="mt-6 grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-2">
                      <InputText
                        type="text"
                        label="Nome"
                        value={theme}
                        onChange={(e) => setTheme(e.target.value)}
                      />
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <InputFile
                        ref={fileRef}
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                        label="Imagem"
                      />
                    </div>
                  </div>

                  <div className="mt-6 grid grid-cols-4 gap-6">
                    <div className="col-span-4 sm:col-span-2">
                      <InputFile
                        ref={pdfRef}
                        accept="application/pdf"
                        label="Pdf"
                      />
                    </div>
                  </div> */}
                </div>
                {/* <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 dark:bg-gray-700">
                  <Button
                    type="button"
                    onClick={() => createTheme()}
                    loading={loading}
                    label={"Criar"}
                    color="primary"
                  />
                </div> */}
              </div>
            </form>
          </section>

          <section aria-labelledby="billing-history-heading">
            <Table
              title="Vídeos"
              data={
                videos
                  ? videos?.video?.map((s) => ({
                      id: s.id,
                      title: s.title,
                      image: s.image.url,
                      order: s.order,
                      url: s.url,
                      sub_title: s.sub_title,
                    }))
                  : []
              }
              fields={[
                {
                  editable: true,
                  field: "title",
                  name: "Título",
                  type: "text",
                },
                {
                  editable: true,
                  field: "order",
                  name: "Ordem",
                  type: "number",
                },
                {
                  editable: false,
                  field: "image",
                  name: "Imagem",
                  type: "url",
                },
                {
                  editable: true,
                  field: "sub_title",
                  name: "Sub Título",
                  type: "text",
                },
              ]}
              onSave={() => {}}
            />
          </section>
        </main>
      </div>
    </>
  );
};

export default VideosAdminPage;
