import React, { useEffect, useState } from "react";
import {
  CalendarIcon,
  ClockIcon,
  CreditCardIcon,
  CursorClickIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { useAuth } from "../../../../../utils/context/auth";
import { useQuery } from "react-query";
// import { paymentsAPI } from "../../../../../api/payments.api";
import { useNavigate } from "react-router-dom";
import CardInfo from "../../../../../components/CardInfo";
// import { reportsAPI } from "../../../../../api/reports.api";
import HeaderDashboard from "../../../../../components/HeaderDashboard";
import BarGraph from "../../../../../components/BarGraph";
import AreaGraph from "../../../../../components/AreaGraph";
import { Role } from "../../../../../utils/interfaces/models";
import { reportAPI } from "../../../../../api/report.api";

const DashboardAdminPage = () => {
  const { user } = useAuth();
  // const { data: payments } = useQuery("payments", paymentsAPI.getAll, {
  //   cacheTime: 1000 * 15,
  //   staleTime: 1000 * 15,
  // });
  // const { data: averageNoteYearly } = useQuery(
  //   "year-user-average",
  //   () => reportsAPI.getUserAverageNoteYearly(),
  //   {
  //     cacheTime: 1000 * 15,
  //     staleTime: 1000 * 15,
  //   }
  // );
  // const { data: averageCompetenceYearly } = useQuery(
  //   "year-user-competence-average",
  //   () => reportsAPI.getUserAverageCompetenceYearly(),
  //   {
  //     cacheTime: 1000 * 15,
  //     staleTime: 1000 * 15,
  //   }
  // );
  const { data: adminReport } = useQuery(
    "admin-report",
    () => reportAPI.adminReport(),
    {
      cacheTime: 1000 * 15,
      staleTime: 1000 * 15,
      refetchOnWindowFocus: false,
    }
  );
  const navigate = useNavigate();
  const [cards, setCards] = useState<
    | {
        name: string;
        href: string;
        subtitle: string;
        icon: any;
        color: string;
        value: string;
      }[]
    | null
  >(null);

  useEffect(() => {
    if (user) {
      const cards = [];

      cards.push({
        name: "Logins diários",
        subtitle: "logins feitos hoje",
        color: "text-secondary",
        icon: CalendarIcon,
        value: (() => {
          if (adminReport && adminReport.loginsToday >= 0) {
            return adminReport.loginsToday + " logins";
          }

          return "0 logins";
        })(),
      });

      cards.push({
        name: "Logins semanais",
        subtitle: "logins feitos na semana",
        color: "text-error",
        icon: CalendarIcon,
        value: (() => {
          if (adminReport && adminReport.loginsWeekly >= 0) {
            return adminReport.loginsWeekly + " logins";
          }

          return "0 logins";
        })(),
      });

      cards.push({
        name: "Logins mensais",
        subtitle: "logins feitos neste mês",
        color: "text-success",
        icon: CalendarIcon,
        value: (() => {
          if (adminReport && adminReport.loginsMonthly >= 0) {
            return adminReport.loginsMonthly + " logins";
          }

          return "0 logins";
        })(),
      });

      setCards(
        Object.assign(
          [],
          [
            {
              name: "Tempo restante na plataforma",
              href: "/app/planos",
              subtitle: "Dias restantes para acessar a plataforma",
              color: "text-primary",
              icon: ClockIcon,
              value: (() => {
                if (!user?.access_until) {
                  return "Nenhum plano ativo ainda";
                }
                const now = new Date();
                const end = new Date(user?.access_until);
                const diff = end.getTime() - now.getTime();
                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                return days + " dias";
              })(),
            },
            ...cards,
            // {
            //   name: "Vídeos já assistidos",
            //   href: "/app/videos",
            //   subtitle: "Assistir vídeos",
            //   icon: CursorClickIcon,
            //   // value: user.videos_watched.length + " vídeos",
            //   value: "7" + " vídeos",
            // },
          ]
        )
      );
    }
  }, [user, adminReport]);

  return (
    <>
      <HeaderDashboard />

      <div className="mt-8">
        <div className="flex justify-between flex-wrap items-center max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {cards?.map((card) => (
            <CardInfo
              color={card.color}
              key={card.name}
              name={card.name}
              icon={card.icon}
              value={card.value}
              subtitle={card.subtitle}
              href={card.href}
              onClick={() => navigate(card.href)}
            />
          ))}
        </div>
      </div>
      <div className="mt-8">
        <div className="flex flex-row flex-wrap gap-4 px-4 sm:px-6 lg:px-8 xl:px-10 items-center justify-between">
          {/* {averageNoteYearly && averageNoteYearly?.data && (
            <AreaGraph
              title={"Suas notas ao longo do tempo"}
              max={1000}
              min={0}
              colorId="colorUv"
              color={"#8884d8"}
              data={averageNoteYearly.data}
            />
          )}
          {averageCompetenceYearly && averageCompetenceYearly?.data && (
            <BarGraph
              title={"Nota em cada competência"}
              max={200}
              min={0}
              color={"#03befc"}
              data={averageCompetenceYearly.data}
            />
          )} */}
        </div>
      </div>
      {/* {payments && payments?.payments && payments?.payments?.length > 0 && (
        <div className="mt-8">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">
              Pagamentos em processo
            </h2>
            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              {payments &&
                payments?.payments?.map((payment, i) => {
                  if (payment.status === "Criado") {
                    return (
                      <div
                        key={payment.id}
                        className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700"
                      >
                        <div className="w-full flex items-center justify-between p-6 space-x-6">
                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="text-gray-900 dark:text-gray-100 text-sm font-medium truncate">
                                {payment.product.title}
                              </h3>
                              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                {payment.status}
                              </span>
                            </div>
                            <p className="mt-1 text-gray-500 dark:text-gray-400 text-sm truncate">
                              R$ {payment.value}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="-mt-px flex divide-x divide-gray-200 dark:divide-gray-700 ">
                            <div className="w-0 flex-1 flex">
                              <a
                                href={payment.approvalUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                              >
                                <CreditCardIcon
                                  className="w-5 h-5 text-gray-400 "
                                  aria-hidden="true"
                                />
                                <span className="ml-3 dark:text-gray-400">
                                  Pagar
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default DashboardAdminPage;
