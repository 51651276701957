import React, { useState } from "react";
import { useQuery } from "react-query";
import { teamAPI } from "../../../../../api/team.api";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../../../components/Table";
import { ChartBarIcon } from "@heroicons/react/solid";
import { CollectionIcon } from "@heroicons/react/outline";
import { studentAPI } from "../../../../../api/student.api";
import Select from "../../../../../components/Select";
import InputText from "../../../../../components/InputText";

const StudentsTeacherPage: React.FC = () => {
  const [filter, setFilter] = useState({ name: "", team: "" });
  const {
    data: students,
    isLoading,
    refetch,
  } = useQuery(["students", filter], () => studentAPI.findAll(filter), {
    enabled: false,
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 0,
    staleTime: 1000 * 0,
  });
  const { data: classes } = useQuery("classes", teamAPI.findAll, {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 0,
    staleTime: 1000 * 0,
  });
  const navigate = useNavigate();

  const findWithFilter = () => {
    refetch();
  };

  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10 lg:py-12 px-4 space-y-8 lg:px-8">
          <section aria-labelledby="billing-history-heading">
            <Table
              find={() => findWithFilter()}
              filterLoading={isLoading}
              filter={true}
              title="Alunos"
              data={
                students?.user
                  ? students?.user?.map((s) => ({
                      id: s.id,
                      name: s.profile?.name,
                      email: s.profile?.user?.email,
                      class: s.team?.name,
                      access_until: s.profile?.user?.access_until,
                    }))
                  : []
              }
              fields={[
                {
                  editable: false,
                  field: "name",
                  name: "Nome",
                  type: "text",
                },
                {
                  editable: false,
                  field: "email",
                  name: "Email",
                  type: "text",
                },
                {
                  editable: false,
                  field: "class",
                  name: "Turma",
                  type: "text",
                },
                {
                  editable: false,
                  field: "access_until",
                  name: "Acesso até",
                  type: "date",
                },
              ]}
              actions={[
                {
                  element: ({ id }: any) => (
                    <Link
                      className="text-blue-500 hover:text-blue-600"
                      // onClick={() => navigate("/app/relatorio/usuario/" + id)}
                      to={"/app/relatorio/usuario/" + id}
                    >
                      <ChartBarIcon color="#0099ff" width={18} height={18} />
                    </Link>
                  ),
                },
                {
                  element: ({ id }: any) => (
                    <Link
                      className="text-blue-500 hover:text-blue-600"
                      // onClick={() => navigate("/app/redacoes/usuario/" + id)}
                      to={"/app/redacoes/usuario/" + id}
                    >
                      <CollectionIcon color="#0099ff" width={18} height={18} />
                    </Link>
                  ),
                },
              ]}
              onSave={(d) => console.log(d)}
            >
              <div className="grid grid-cols-4 gap-6 mb-4">
                <div className="col-span-4 sm:col-span-2">
                  <InputText
                    type="text"
                    label="Nome"
                    value={filter.name}
                    onChange={(e) =>
                      setFilter({ ...filter, name: e.target.value })
                    }
                  />
                </div>

                <div className="col-span-4 sm:col-span-2">
                  <Select
                    label="Turma"
                    value={filter.team}
                    onChange={(e) =>
                      setFilter({ ...filter, team: e.target.value })
                    }
                  >
                    <option value="">Todas</option>
                    {classes &&
                      classes?.team?.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
            </Table>
          </section>
        </main>
      </div>
    </>
  );
};

export default StudentsTeacherPage;
