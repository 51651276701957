import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import InputText from "../../../../components/InputText";
import Button from "../../../../components/Button";
import UploadPhoto from "../../../../components/UploadPhoto";
import { textAPI } from "../../../../api/text.api";
import { API_URL } from "../../../../utils/api";
import { useAuth } from "../../../../utils/context/auth";
import Loading from "../../../../components/Loading";
import RegionSelect from "react-region-select";
import Region from "../../../../components/Region";
import { classNames } from "../../../../utils/functions/ClassNames";
import { COMPETENCES } from "../../../../utils/competences";
import { Text_Status } from "../../../../utils/interfaces/models";
import Resizer from "react-image-file-resizer";

const WritePage = () => {
  const { notification, user, setUser } = useAuth();
  const [uploading, setUploading] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: text,
    refetch,
    isLoading,
  } = useQuery(["text", id], () => textAPI.findOne({ id: id! }), {
    refetchOnWindowFocus: false,
  });
  const [photoSrc, setPhotoSrc] = useState<any>(null);

  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      try {
        Resizer.imageFileResizer(
          file,
          800, // largura desejada
          1150, // altura será calculada automaticamente
          // file.name.split(".").pop() === "jpeg"
          //   ? "jpg"
          //   :
          file.name.split(".").pop() || "webp", // formato da imagem
          50, // qualidade da imagem (0-100)
          0, // rotação da imagem (0 = sem rotação)
          (uri) => {
            resolve(uri);
          },
          "file" // tipo de saída ("file" para retornar um arquivo, ou "base64" para retornar uma string base64)
        );
      } catch (error) {
        resolve(null);
      }
    });

  const upload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files && e.target?.files[0]) {
      const file_name = e.target?.files[0]?.name;

      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      reader.onload = async (el: ProgressEvent<FileReader>) => {
        const lastImage = photoSrc;
        setPhotoSrc({
          file_name: file_name,
          url: el.target?.result,
        });
        console.log(1);
        if (e.target?.files && e.target?.files[0]) {
          setUploading(true);
          console.log(2);

          if (e.target?.files[0].size > 8090000) {
            console.log(3);
            notification("Tamanho máximo: 8MB", { type: "warning" });
            setPhotoSrc(lastImage);
            setUploading(false);
            return;
          }

          console.log(e.target?.files[0].size);

          const fileResized: File = (await resizeFile(
            e.target.files[0]
          )) as File;
          console.log(4);
          console.log(fileResized.size);

          let fileToSend = e.target?.files[0];

          if (fileResized) {
            fileToSend =
              fileResized.size > e.target?.files[0].size
                ? e.target?.files[0]
                : fileResized;
          }
          console.log(5);
          console.log(e.target.files[0], fileResized, fileToSend);

          const { error, message } = await textAPI.addImage({
            id: id!,
            image: fileToSend,
          });

          setUploading(false);

          if (error && message) {
            setPhotoSrc(lastImage);

            notification(message ?? "Erro ao fazer upload da imagem", {
              type: "warning",
            });
          } else {
            notification("Imagem atualizada com sucesso!", {
              type: "success",
            });
          }
        }
      };
    }
  };

  const removeImg = async () => {
    if (photoSrc) {
      setPhotoSrc(null);
    } else if (text?.text?.image) {
      setUploading(true);

      const { error, message } = await textAPI.deleteImage({ id: id! });

      setUploading(false);
      if (error && message) {
        notification(message ?? "Erro ao remover imagem", {
          type: "warning",
        });
      } else {
        notification("Imagem removida com sucesso!", {
          type: "success",
        });

        refetch();
      }
    }
  };

  const finishText = async () => {
    setUploading(true);
    const { text, error, message } = await textAPI.updateToSent({ id: id! });
    setUploading(false);
    if (!error) {
      notification("Texto enviado com sucesso!", {
        type: "success",
      });

      return navigate("/app/minhas-redacoes");
    }

    return notification(
      message ?? "Erro ao finzalizar texto. Tente novamente",
      {
        type: "warning",
      }
    );
  };

  const cancelText = async () => {
    setCancelling(true);
    const { text, error, message } = await textAPI.cancelText({ id: id! });
    setCancelling(false);
    if (!error) {
      notification("Texto cancelado com sucesso!", {
        type: "success",
      });

      setUser({
        ...user,
        profile: {
          ...user?.profile,
          points: (user?.profile?.points || 0) + 1,
        },
      });

      return navigate("/app/minhas-redacoes");
    }

    return notification(message ?? "Erro ao cancelar texto. Tente novamente", {
      type: "warning",
    });
  };

  const unsendText = async () => {
    setCancelling(true);
    const { text, error, message } = await textAPI.unsendText({ id: id! });
    setCancelling(false);
    if (!error) {
      notification(
        "Envio cancelado com sucesso! Você pode reenviar novamente a qualquer momento ou cancelar seu texto.",
        {
          type: "success",
        }
      );

      return navigate("/app/minhas-redacoes");
    }

    return notification(
      message ?? "Erro ao cancelar envio. Tente novamente em alguns instantes.",
      {
        type: "warning",
      }
    );
  };

  const isRevision = () => {
    return (
      text?.text?.status !== "Criado" &&
      text?.text?.status !== "Enviado" &&
      text?.text?.status !== "Corrigindo"
    );
  };

  if (isLoading) {
    return <Loading big padding />;
  }

  return (
    <>
      <div className="w-full flex flex-col p-8 bg-base-100 shadow-md">
        <div className="w-full mb-4 border-b-[1px] border-t-gray-400 dark:border-gray-700 border-t-0">
          <p className="text-xl dark:text-white mb-4">
            {text?.text?.theme?.title}
          </p>
        </div>
        <div className="w-full">
          {text?.text?.status === Text_Status.Criado ? (
            <p className="text-sm text-gray-500 dark:text-gray-400">
              A partir da leitura dos textos motivadores e com base nos
              conhecimentos construidos ao longo de sua formação, redija um
              texto dissertativo-argumentativo em modalidade escrita formal da
              língua portuguesa sobre o tema <b>{text?.text?.theme?.title}</b>,
              apresentando proposta de intervenção que respeite os direitos
              humanos. Selecione, organize e relacione, de forma coerente e
              coesa, argumentos e fatos para defesa de seu ponto de vista.
            </p>
          ) : (
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Após o texto ser corrigido, você poderá ver a correção sobre o
              tema: <b>{text?.text?.theme?.title}</b>. Estará incluído as notas
              em cada competência, areas marcadas no texto com informações sobre
              o erro e comentários adicionais do corretor.
            </p>
          )}
        </div>
        <div className="w-full mt-4 flex flex-row items-center justify-start space-x-8 flex-wrap">
          <a
            href={text?.text?.theme?.motivational?.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row items-center justify-start text-blue-600 w-auto cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-4 text-blue-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
              />
            </svg>
            <p className="truncate">PDF motivacional</p>
          </a>
          <div className="flex flex-1 flex-row items-center justify-end gap-2 flex-wrap">
            {text && text?.text?.status === "Criado" && (
              <>
                <Button
                  responsive
                  loading={uploading}
                  disabled={uploading}
                  label="Enviar para correção"
                  type="button"
                  color="success"
                  onClick={() => finishText()}
                />
                <Button
                  responsive
                  loading={cancelling}
                  disabled={cancelling}
                  label="Cancelar redação"
                  type="button"
                  color="danger"
                  onClick={() => cancelText()}
                />
              </>
            )}
            {text && text?.text?.status === "Enviado" && (
              <>
                <Button
                  responsive
                  loading={cancelling}
                  disabled={cancelling}
                  label="Cancelar envio"
                  type="button"
                  color="danger"
                  onClick={() => unsendText()}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="m-8 p-6 rounded-md shadow-md dark:shadow-gray-700 bg-base-100 flex flex-col">
        {(photoSrc || text?.text?.image) && text?.text?.status === "Criado" && (
          <div className="p-2 flex-row flex items-center space-x-2 shadow-sm justify-start shadow-gray-400 dark:shadow-gray-800 rounded-sm bg-gray-100 dark:bg-gray-700 w-1/2 md:w-2/3 lg:w-1/4 ">
            <img
              src={photoSrc?.url || text?.text?.image?.url}
              alt={photoSrc?.file_name || text?.text?.image?.file_name}
              className="h-8 w-8 rounded-sm"
            />
            <p className="text-gray-500 dark:text-gray-300 text-xs truncate">
              {photoSrc?.file_name || text?.text?.image?.file_name}
            </p>
            {uploading ? (
              <Loading />
            ) : (
              <Button type="button" onClick={() => removeImg()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </Button>
            )}
          </div>
        )}
        <div
          className={classNames(
            isRevision()
              ? "grid grid-cols-3 gap-5"
              : "flex flex-row items-center justify-center",
            "w-full  border-t-[0px] mt-0 border-t-gray-400 dark:border-t-gray-700 pt-4"
          )}
        >
          {photoSrc || text?.text?.image ? (
            <RegionSelect
              style={{ border: "black" }}
              regions={
                text?.text?.areas
                  ? text?.text?.areas?.map((a) => {
                      if (a.width <= 3) {
                        a.width = 3;
                      }
                      if (a.height <= 3) {
                        a.height = 3;
                      }
                      return a;
                    })
                  : []
              }
              className={classNames(
                isRevision()
                  ? "w-[100%] col-span-3 lg:col-span-2"
                  : "h-[80%] w-[80%]",
                "rounded-md"
              )}
              regionRenderer={({ data }: { data: any; isChanging: any }) => {
                return (
                  <Region
                    competences={COMPETENCES}
                    onlyView
                    data={{ ...data }}
                    removeRegion={() => {}}
                    setCompetence={() => {}}
                  />
                );
              }}
            >
              <img
                src={photoSrc?.url || text?.text?.image?.url}
                alt="Redação"
                className="h-[100%] w-[100%] rounded-md"
              />
            </RegionSelect>
          ) : (
            <UploadPhoto onChange={(e) => upload(e)} />
          )}
          {isRevision() && (
            <div className="col-span-3 lg:col-span-1 space-y-2">
              <InputText
                label="Competência 1"
                disabled
                value={text?.text?.enem_style?.comp1?.toString()}
              />
              <InputText
                label="Competência 2"
                disabled
                value={text?.text?.enem_style?.comp2?.toString()}
              />
              <InputText
                label="Competência 3"
                disabled
                value={text?.text?.enem_style?.comp3?.toString()}
              />
              <InputText
                label="Competência 4"
                disabled
                value={text?.text?.enem_style?.comp4?.toString()}
              />
              <InputText
                label="Competência 5"
                disabled
                value={text?.text?.enem_style?.comp5?.toString()}
              />
              <p className="text-lg strong dark:text-white">
                Nota final:{" "}
                {(text?.text?.enem_style?.comp1 ?? 0) +
                  (text?.text?.enem_style?.comp2 ?? 0) +
                  (text?.text?.enem_style?.comp3 ?? 0) +
                  (text?.text?.enem_style?.comp4 ?? 0) +
                  (text?.text?.enem_style?.comp5 ?? 0)}
              </p>
              <p className="text-lg strong dark:text-white border-t-[1px] pt-2 border-t-gray-300">
                Observação do corretor:{" "}
              </p>
              <p className="text-sm dark:text-white">
                {text?.text?.corrector_observation}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WritePage;
