import React from "react";
import { classNames } from "../../utils/functions/ClassNames";
import Loading from "../Loading";

interface Props {
  type?: "button" | "submit" | "reset" | undefined;
  version?: "block";
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  responsive?: boolean;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
}

const Button: React.FC<Props> = (props) => {
  return (
    <button
      disabled={props.disabled || props.loading}
      type={props.type || "button"}
      className={classNames(
        props.color === "success"
          ? "inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          : "",
        props.color === "primary"
          ? "flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          : "",
        props.color === "light"
          ? "text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
          : "",
        props.color === "danger"
          ? "text-white hover:bg-red-700 focus:ring-4 focus:ring-red-700 rounded-lg border border-red-200 text-sm font-medium px-5 py-2 focus:z-10 bg-red-500 dark:border-red-500 dark:hover:bg-red-600"
          : "",
        props.version === "block" ? "w-full" : "",
        props.responsive ? "text-xs lg:text-sm" : "",
        props.className ?? ""
      )}
      onClick={props.onClick}
    >
      {props.loading && <Loading />}
      {props.loading ? "Carregando..." : props.label ?? props.children}
    </button>
  );
};

export default Button;
