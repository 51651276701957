import React, { useEffect, useRef } from "react";
import moment from "moment";
import { classNames } from "../../utils/functions/ClassNames";

interface Props {
  label?: string;
  value?: string;
  placeholder?: string;
  defaultValue?: string;
  className?: string;
  name?: string;
  disabled?: boolean;
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DatePicker: React.FC<Props> = (props) => {
  const dateRef = useRef<any>(null);

  return (
    <div
      className={classNames(
        props.disabled ? "cursor-no-drop" : "",
        "form-control w-full"
      )}
    >
      <label className="label">
        <span className="label-text">{props.label}</span>
      </label>
      <input
        ref={dateRef}
        onClick={() => dateRef?.current?.showPicker()}
        className={classNames(
          props.className ?? "",
          "input input-bordered w-full"
        )}
        type="date"
        defaultValue={props.defaultValue}
        value={props.value}
        min={moment(Date.now()).format("YYYY-MM-DD")}
        onChange={props.onChange}
        max="2060-12-31"
      ></input>
    </div>
  );
};

export default DatePicker;
