import { API_URL } from "../utils/api";
import { ErrorInterface } from "../utils/interfaces/error.interface";
import { Corrector } from "../utils/interfaces/models";
import { OnlyIDInterface } from "../utils/interfaces/only-id.interface";

interface CreateInterface {
  email: string;
  password: string;
  name: string;
  access_until: Date;
  verified: boolean;
  force_new_password: boolean;
}

interface CreateResponseInterface extends ErrorInterface {
  user: Corrector;
}

interface FindAllResponseInterface extends ErrorInterface {
  users: Corrector[];
}

interface FindOneResponseInterface extends ErrorInterface {
  user: Corrector;
}

interface UpdateValuePerTextResponseInterface extends ErrorInterface {
  user: Corrector;
}

interface UpdateValuePerTextData extends OnlyIDInterface {
  value_per_text: number;
}

export const correctorAPI = {
  create: async (data: CreateInterface): Promise<CreateResponseInterface> => {
    return await fetch(`${API_URL}/corrector`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { user: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findAll: async (): Promise<FindAllResponseInterface> => {
    return await fetch(`${API_URL}/corrector`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { users: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  findOne: async (data: OnlyIDInterface): Promise<FindOneResponseInterface> => {
    return await fetch(`${API_URL}/corrector/${data.id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { user: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },

  updateValuePerText: async (
    id: OnlyIDInterface,
    data: UpdateValuePerTextData
  ): Promise<UpdateValuePerTextResponseInterface> => {
    return await fetch(`${API_URL}/corrector/${id.id}/value-per-text`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.error) {
          return data;
        }
        return { user: data };
      })
      .catch(() => ({
        error: "Error",
        message: "Ocorreu um erro, por favor reinicie a página",
      }));
  },
};
